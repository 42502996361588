import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import MenuContents from "../../atoms/SidemenuItem";

import { ReactComponent as Logo } from "../../../assets/svg/logo.svg";
import { ReactComponent as GateWayTxt } from "../../../assets/svg/gateway_txt.svg";
import IconHome from "../../../assets/svg/icon_home.svg";
import IconLogout from "../../../assets/svg/icon_logout.svg";
import UserDefaultImg from "../../../assets/png/user_default_picture.png";
import { useMutation, useQuery } from "react-query";
import { logout, userCheck } from "hooks/auth";

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  height: 100%;
  background-color: var(--color-NormalGreen);
  transition: all 200ms ease-in-out;
  z-index: 1000;
`;

const Header = styled.div`
  margin: 40px 0;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Blank = styled.div`
  height: 24px;
`;

const MenuList = styled.div`
  flex-grow: 1;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 80px 0;
`;

const UserPicture = styled.div`
  margin-top: 40px;
  margin-bottom: 8px;
  width: 48px;
  height: 48px;
`;

const UserName = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #283532;
`;

function SideMenu() {
  const [username, setUsername] = useState();
  useQuery("user", userCheck, {
    retry: 1,
    onSuccess: (data) => {
      setUsername(data.username);
    },
    onError: (data) => {
      setUsername("");
    },
  });

  const logoutMutation = useMutation(logout, {
    onSuccess: (data, variables, context) => {
      window.alert("로그아웃되었습니다.");
      sessionStorage.removeItem("token");
      window.location.href = "/";
    },
    onError: (error) => {
      window.alert("서버와의 통신에 실패했습니다. 관리자에게 문의해주세요.");
    },
  });

  return (
    <Container>
      <Header>
        <Link to="/">
          <HeaderWrapper>
            <Logo />
            <Blank />
            <GateWayTxt />
          </HeaderWrapper>
        </Link>
      </Header>
      <MenuList>
        <Link to="/">
          <MenuContents iconUrl={IconHome} btnTxt="프로젝트 관리" />
        </Link>
      </MenuList>
      <Footer>
        <MenuContents
          iconUrl={IconLogout}
          btnTxt="로그아웃"
          onClick={(e) => logoutMutation.mutate()}
        />
        <UserPicture>
          <img src={UserDefaultImg} />
        </UserPicture>
        <UserName>{username}</UserName>
      </Footer>
    </Container>
  );
}

export default SideMenu;
