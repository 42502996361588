import axios from "axios";
import { apiUrl } from "hooks/urls.js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAlert from "../useAlert";
import { useNavigate } from "react-router-dom";
import useAlertSign from "../useAlertSign";

const getEndpointList = (params) => {
  return axios.get(`${apiUrl("project")}/${params.projectId}/endpoints`, {
    params: params,
  });
};

export const useEndpointList = (params) => {
  return useQuery("endpoints", (e) => getEndpointList(params), {
    refetchOnWindowFocus: false,
    suspense: true,
  });
};

const getEndpointDetail = (params) => {
  return axios.get(
    `${apiUrl("project")}/${params.projectId}/endpoints/${params.id}`
  );
};

export const useEndpointDetail = (params) => {
  return useQuery("endpoint", (e) => getEndpointDetail(params), {
    refetchOnWindowFocus: false,
    suspense: true,
  });
};

const createEndpoint = (data) => {
  return axios.post(`${apiUrl("project")}/${data.projectId}/endpoints`, data);
};

export const useEndpointCreate = () => {
  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(createEndpoint, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("endpoints");
      // navigate('/Project');
      setAlertSign("성공적으로 엔드포인트를 등록하였습니다");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

const updateEndpoint = (data) => {
  return axios.put(
    `${apiUrl("project")}/${data.projectId}/endpoints/${data.id}`,
    data
  );
};

export const useEndpointUpdate = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useAlert();

  return useMutation(updateEndpoint, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("endpoints");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

const deleteEndpoint = (params) => {
  return axios.delete(
    `${apiUrl("project")}/${params.projectId}/endpoints/${params.id}`
  );
};

export const useEndpointDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteEndpoint, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("endpoints");
    },
  });
};
