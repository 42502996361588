import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  width: ${(props) => props.width};
  min-width: 72px;
  height: 60px;
  background-color: ${(props) => (props.disabled ? "#E6E6E6" : props.bgColor)};
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "#E6E6E6" : props.hvColor};
  }
`;

const ButtonText = styled.p`
  padding: 0 16px;
  font-size: 15px;
  font-weight: 800;
  color: ${(props) => props.txtColor};
`;

const LeftIcon = styled.div`
  display: ${(props) => (props.leftIcon === "none" ? "none" : "block")};
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.imgUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const RightIcon = styled.div`
  display: ${(props) => (props.rightIcon === "none" ? "none" : "block")};
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.imgUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

function Button(props) {
  const {
    bgColor,
    hvColor,
    width,
    imgUrl,
    leftIcon,
    txtColor,
    buttonName,
    rightIcon,
    onClick,
    disabled,
    className,
  } = props;

  const onClickHandler = (e) => {
    if (disabled) {
      return false;
    } else if (onClick) {
      onClick();
    } else {
      return false;
    }
  };

  return (
    <Container
      className={`buttons ${className}`}
      bgColor={bgColor}
      hvColor={hvColor}
      width={width}
      onClick={(e) => onClickHandler(e)}
      disabled={disabled}
    >
      <LeftIcon imgUrl={imgUrl} leftIcon={leftIcon}></LeftIcon>
      <ButtonText txtColor={txtColor}>{buttonName}</ButtonText>
      <RightIcon imgUrl={imgUrl} rightIcon={rightIcon}></RightIcon>
    </Container>
  );
}

export default Button;
