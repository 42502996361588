import React from "react";
import styled from "styled-components";
import CopyIcon from "../../../assets/svg/icon_copy.svg";
import { ReactComponent as DeleteIconSvg } from "../../../assets/svg/icon_delete_red.svg";
import { ReactComponent as ActiveIconSvg } from "assets/svg/icon_active.svg";
import { ReactComponent as DisabledIconSvg } from "assets/svg/icon_disabled.svg";
import { ReactComponent as AggregateIconSvg } from "assets/svg/icon_count.svg";
import moment from "moment";
import "moment-timezone";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: ${(props) => props.tdFlex};
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
`;

const DeleteIcon = styled(DeleteIconSvg)`
  width: 25px;
  height: 25px;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const AggregateIcon = styled(AggregateIconSvg)`
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const ActiveIcon = styled(ActiveIconSvg)`
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const DisabledIcon = styled(DisabledIconSvg)`
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const TdText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: var(--color-LightGreen);
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CopyButton = styled.div`
  display: ${(props) => (props.copyDP ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background-color: var(--color-BaseBlue);
  transition: all 100ms;
  cursor: pointer;

  &:hover {
    background-color: #e0e9f5;
  }
`;

const CopyImage = styled.div`
  width: 11px;
  height: 12px;
  background-image: url(${(props) => props.copyImg});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

function Td({
  tdFlex,
  tdText,
  type,
  deleteHandler,
  obj,
  aggregateModalHandler,
  copyDP,
}) {
  const { setAlertSign } = useAlertSign();
  const copyAction = async (e, text) => {
    e.stopPropagation();
    const textArea = document.createElement("textarea");

    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();

    document.execCommand("copy");
    document.body.removeChild(textArea);
    setAlertSign("API 키가 복사되었습니다");
  };

  return (
    <Container tdFlex={tdFlex}>
      {type === "is_active" && (tdText ? <ActiveIcon /> : <DisabledIcon />)}
      {type === "aggregate_icons" && (
        <AggregateIcon onClick={(e) => aggregateModalHandler(e, obj)} />
      )}
      {type === "delete_icons" && (
        <DeleteIcon onClick={(e) => deleteHandler(e, obj)} />
      )}
      {type === "quota_enabled" && (tdText ? <ActiveIcon /> : <DisabledIcon />)}
      {type === "throttling_enabled" &&
        (tdText ? <ActiveIcon /> : <DisabledIcon />)}

      <TdText>
        {type === "created_at"
          ? moment
              .tz(tdText, "YYYY-MM-DD hh:mm", "UTC")
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .format("YYYY-MM-DD")
          : tdText}
      </TdText>
      <CopyButton copyDP={copyDP} onClick={(e) => copyAction(e, tdText)}>
        <CopyImage copyImg={CopyIcon} />
      </CopyButton>
    </Container>
  );
}

export default Td;
