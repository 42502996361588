import axios from "axios";
import { apiUrl } from "./urls";

axios.defaults.headers.common[
  "Authorization"
] = `Token ${sessionStorage.getItem("token")}`;

export const login = async ({ username, password }) => {
  const user = {
    username: username,
    password: password,
  };
  const { data } = await axios.post(apiUrl("login"), user);
  return data;
};

export const logout = async () => {
  const token = { auth_token: sessionStorage.getItem("token") };
  const { data } = await axios.post(apiUrl("logout"), token);
  return data;
};

export const userCheck = async () => {
  const { data } = await axios.get(apiUrl("user") + "/check");
  return data;
};
