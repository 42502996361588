import React from "react";
import styled from "styled-components";

import Layout from "../../organisms/ModalLayout";
import InfoItem from "../../atoms/InformationItem";
import AnnouncementBox from "../../molecules/AnnouncementBox";

const ModalText = styled.p`
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-BoldGreen);
`;

const InformationBox = styled.div`
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 16px;
  border: solid 1px var(--color-GreyGreen);
`;

function DeleteModal({
  display,
  modalTitle,
  modalTxt,
  boxContents,
  announces,
  close,
  confirmButtonClick,
}) {
  return (
    <Layout
      modalDP={display}
      width="500px"
      modalTitle={modalTitle}
      confirmButtonName={"삭제"}
      defaultButtonName={"취소"}
      confirmButtonWidth="calc(100% - 50px)"
      defaultButtonWidth="calc(100% - 50px)"
      buttonDisplay={true}
      confirmButtonClick={confirmButtonClick}
      close={close}
    >
      <ModalText>{modalTxt}</ModalText>
      <InformationBox>
        {boxContents.map((content, idx) => (
          <InfoItem
            key={idx}
            titleWidth="160px"
            itemTitle={content?.key}
            itemContent={content?.value}
          />
        ))}
      </InformationBox>
      <AnnouncementBox announces={announces} />
    </Layout>
  );
}

export default DeleteModal;
