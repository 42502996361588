import React, { useState } from "react";
import styled from "styled-components";

import Select from "../../atoms/Select";
import InputTitle from "../../atoms/InputTitle";
import Tooltip from "../../atoms/Tooltip";

const Container = styled.div`
  width: ${(props) => props.width};
  min-width: 200px;
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
`;

const EssentailSign = styled.p`
  display: ${(props) => (props.EsnSignDp ? "none" : "block")};
  margin-right: 8px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-EmphasisGreen);
`;

function InputwithTitle({
  width,
  EsnSignDp,
  selectTitle,
  tooltipTxt,
  tooltipDP,
  value,
  onChange,
  items,
  name,
  disabled,
}) {
  return (
    <Container width={width}>
      <TitleWrap>
        <EssentailSign EsnSignDp={EsnSignDp}>*</EssentailSign>
        <InputTitle inputTitle={selectTitle} />
        <Tooltip tooltipTxt={tooltipTxt} tooltipDP={tooltipDP} />
      </TitleWrap>
      <Select
        value={value}
        onChange={onChange}
        items={items || []}
        name={name}
        disabled={disabled}
      />
    </Container>
  );
}

export default InputwithTitle;
