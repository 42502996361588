import React, { useEffect, useId, useState } from "react";
import styled, { css } from "styled-components";

import SelectIcon from "../../../assets/svg/icon_select.svg";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-BoldGreen);
  background-image: url(${(props) => props.arrowUrl});
  background-position: calc(100% - 24px) 50%;
  background-repeat: no-repeat;
  border-radius: 16px;
  border: solid 1px #dbede7;

  ${(props) =>
    props.disabled &&
    css`
      background-color: #e6e6e6 !important;
      border-color: #b4b4b4 !important;
    `}
`;

const SelectBox = styled.select`
  box-sizing: border-box;
  padding: 0 24px;
  width: 100%;
  height: 100%;
  border: 0;

  ::placeholder {
    color: var(--color-LightGreen);
  }

  &:focus-visible {
    outline: none;
    border-color: var(--color-EmphasisGreen);
  }

  &::-ms-expand {
    display: none;
  }
`;

const OptionBox = styled.option`
  height: 60px;
`;

function Select({ placeholder, onChange, items, name, disabled, value }) {
  const [text, setText] = useState();
  const id = useId();
  const changeHandler = (event) => {
    setText(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };
  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <Container
      placeholder={placeholder}
      arrowUrl={SelectIcon}
      disabled={disabled}
    >
      <SelectBox
        onChange={changeHandler}
        value={text}
        name={name}
        disabled={disabled}
      >
        {items.map((item, idx) =>
          item.name ? (
            <OptionBox key={id + idx} value={item.id}>
              {item.name}
            </OptionBox>
          ) : (
            <OptionBox key={id + idx}>{item}</OptionBox>
          )
        )}
      </SelectBox>
    </Container>
  );
}

export default Select;
