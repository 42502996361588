import React, { useState } from "react";
import styled from "styled-components";

import Header from "../../molecules/ModalHeader";
import Footer from "../../molecules/ModalFooter";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${(props) => props.display};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.24);
  z-index: 10000;
`;

const ModalBox = styled.div`
  width: ${(props) => props.width};
  height: fit-content;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 0;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  padding: 40px;
  width: calc(100% - 80px);
`;

function ModalLayout(props) {
  const { modalDP, width, modalTitle, children, close } = props;

  return (
    <Container display={modalDP ? "flex" : "none"}>
      <ModalBox className="modalBox" width={width}>
        <Header modalTitle={modalTitle} closeIconClick={(e) => close()} />
        <ContentWrapper>{children}</ContentWrapper>
        <Footer {...props} defaultButtonClick={(e) => close()} />
      </ModalBox>
    </Container>
  );
}

export default ModalLayout;
