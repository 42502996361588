import React from "react";
import styled from "styled-components";
import { ReactComponent as IndicatorImg } from "assets/svg/indicator.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #55c595;
`;

const LoadText = styled.h2`
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Gmarket sans";
  color: #fff;
`;

const IndicatorWrap = styled.div`
  display: flex;
  align-items: center;
  width: 4%;
  height: 4%;
`;

const Indicator = styled(IndicatorImg)``;

function Loading() {
  const override = {
    borderColor: "white",
    marginLeft: "10px",
  };

  return (
    <Container>
      <LoadText>LOADING</LoadText>
      <IndicatorWrap>
        <Indicator className="loading" />
      </IndicatorWrap>
    </Container>
  );
}

export default Loading;
