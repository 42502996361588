import React from "react";
import styled from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(600px - 20px);
  height: 100%;
  min-height: 832px;
  background-color: #ffffff;
  border-radius: 32px;
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  padding: 40px;
  width: 100%;
`;

function DetailBox({ children }) {
  return (
    <Container>
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  );
}

export default DetailBox;
