import React from "react";
import styled from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 8px 8px 0;
  padding: 12px;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  background-color: ${(props) => props.bgColor};
  transition: all 100ms;

  &:nth-child(12n) {
    margin: 0 0 8px 0;
  }

  &:hover {
    transform: scale(1.02);
  }
`;

const TopText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.fontColor};
`;

const BottomText = styled.p`
  font-size: 14px;
  font-weight: 800;
  text-align: right;
  overflow-wrap: break-word;
  color: ${(props) => props.fontColor};
`;

function CountBox({ bgColor, topTxt, bottomTxt, fontColor }) {
  return (
    <Container bgColor={bgColor}>
      <TopText fontColor={fontColor}>{topTxt}</TopText>
      <BottomText fontColor={fontColor}>{bottomTxt}</BottomText>
    </Container>
  );
}

export default CountBox;
