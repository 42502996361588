import React from "react";
import AlertSign from "../../organisms/AlertSign";
import useAlertSign from "../../../hooks/useAlertSign";

function AlertSigns() {
  const { text, display } = useAlertSign();

  return <>{display && <AlertSign alertText={text} />}</>;
}

export default AlertSigns;
