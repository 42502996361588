import axios from "axios";
import { apiUrl } from "hooks/urls.js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAlert from "../useAlert";

const getUsagePlanApikeys = (params) => {
  return axios.get(
    `${apiUrl("project")}/${params.projectId}/usageplans/${params.id}/apikeys`
  );
};

const getApikeysByPlans = (query) => {
  return axios.get(apiUrl(`apikeysByPlans`), { params: query });
};

const createUsagePlanApiKey = (data) => {
  return axios.post(
    `${apiUrl("project")}/${data.projectId}/usageplans/${
      data.apiusageplan
    }/apikeys`,
    data
  );
};

const deleteUsagePlanApiKey = (params) => {
  return axios.delete(
    `${apiUrl("project")}/${params.projectId}/usageplans/${
      params.apiusageplan
    }/apikeys/${params.apikey}`
  );
};

export const useUsagePlanApiKeys = (params) => {
  return useQuery("UsagePlanApiKeys", (e) => getUsagePlanApikeys(params), {
    refetchOnWindowFocus: false,
  });
};

export const useApiKeysByPlans = (params) => {
  return useQuery(["usagekeys", params], (e) => getApikeysByPlans(params), {
    refetchOnWindowFocus: false,
  });
};

export const useUsagePlanApiKeyCreate = () => {
  const { setAlert } = useAlert();
  const queryClient = useQueryClient();

  return useMutation(createUsagePlanApiKey, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("UsagePlanApiKeys");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

export const useUsagePlanApiKeyDelete = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useAlert();

  return useMutation(deleteUsagePlanApiKey, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("UsagePlanApiKeys");
      queryClient.invalidateQueries("usageplans");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};
