import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Table from "components/organisms/ConnectTable";
import {
  useEndPointUsagePlanCreate,
  useEndPointUsagePlanDelete,
  useEndPointUsagePlans,
} from "hooks/queries/useEndPointUsagePlan";
import Modal from "components/templates/addMappedModal";
import { useUsagePlanList } from "../../../../hooks/queries/useUsagePlans";
import DeleteModal from "../../../../components/templates/DeleteModal";
import useAlert from "../../../../hooks/useAlert";
import { useApiKeysByPlans } from "../../../../hooks/queries/useUsagePlanApiKeys";
import useAlertSign from "../../../../hooks/useAlertSign";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
  padding: 0 40px 40px 40px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ContentsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  box-sizing: border-box;
  margin-top: 24px;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  margin-bottom: 24px;
  width: 100%;
  border-bottom: solid 1px var(--color-BgBlue);
`;

const EndpointName = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: "Gmarket Sans";
`;

const BoxButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;

const EndpointDescription = styled.p`
  width: 100%;
  max-width: 100%;
  min-height: 60px;
`;

const InfoWrap = styled.div`
  displat: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  width: 100%;
`;

function EndPointUsagePlanList() {
  const { projectId, id } = useParams();
  // 사용량 계획 연결 추가 모달 visible
  const [mapModal, setMapModal] = useState(false);
  // 사용량 계획 연결추가 체크 항목 (ID: array)
  const [addUsagePlans, setAddUsagePlans] = useState([]);
  // 사용량 계획 연결제거 체크 항목 (ID: array)
  const [removeUsagePlans, setRemoveUsagePlans] = useState([]);
  // 삭제 컨펌 모달
  const [deleteModal, setDeleteModal] = useState(false);
  // tab
  const [searchValue, setSearchValue] = useState("");
  const [unUsedUsagePlans, setUnUsedUsagePlans] = useState([]);

  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();

  const usageplanThs = [
    { key: "checkbox", label: "checkbox" },
    { key: "index", label: "번호", flex: 1 },
    { key: "name", label: "사용량계획 이름", flex: 4 },
    { key: "desc", label: "사용량계획 설명", flex: 4 },
  ];

  // 매핑 관계
  const mappedData = useEndPointUsagePlans({ id: id, projectId: projectId });
  const endPointUsagePlans = mappedData.data?.data || [];

  // 전체 사용량 계획
  const usagePlan = useUsagePlanList({
    id: id,
    projectId: projectId,
    no_page: true,
  });
  const usagePlanList = usagePlan.data?.data || [];

  useEffect(() => {
    if (usagePlanList && !usagePlanList.results) {
      let addUsagePlans =
        usagePlanList.filter(
          (row) =>
            endPointUsagePlans.findIndex(
              (usageplan) => usageplan.id === row.id
            ) === -1
        ) || [];
      if (unUsedUsagePlans.length !== addUsagePlans.length) {
        let plans = new Set(addUsagePlans);
        setUnUsedUsagePlans([...plans]);
      }
    }
  }, [endPointUsagePlans, usagePlanList]);

  // 사용량 계획 연결 추가
  const mapCreate = useEndPointUsagePlanCreate();

  const addHandler = (e) => {
    if (addUsagePlans.length < 1) {
      setAlert(
        "fail",
        false,
        "사용량 계획 연결추가 오류",
        "선택된 사용량 계획이 없습니다. 사용량 계획을 선택해주세요."
      );
    } else {
      addUsagePlans.map((row) => {
        mapCreate.mutate(
          { apiendpoint: id, apiusageplan: row, projectId: projectId },
          {
            onSuccess: ({ data }) => {
              setMapModal(false);
              setAlertSign("사용량 계획 연결 추가를 완료하였습니다.");
              setAddUsagePlans([]);
            },
          }
        );
      });
    }
  };

  // 사용량 계획 연결 제거
  const removeModalHandler = () => {
    if (removeUsagePlans.length < 1) {
      setAlert(
        "fail",
        false,
        "사용량 계획 연결제거 오류",
        "선택된 사용량 계획이 없습니다. 사용량 계획을 선택해주세요."
      );
    } else {
      setDeleteModal(!deleteModal);
    }
  };

  const mapDelete = useEndPointUsagePlanDelete();
  const removeQuery = () => {
    removeUsagePlans.map((row) => {
      mapDelete.mutate(
        { apiendpoint: id, apiusageplan: row, projectId: projectId },
        {
          onSuccess: ({ data }) => {
            setDeleteModal(false);
            setAlertSign("사용량 계획 연결제거를 완료하였습니다.");
            setRemoveUsagePlans((removeUsagePlans) =>
              removeUsagePlans.splice(0)
            );
          },
        }
      );
    });
  };

  const removeAllCheckHandler = (checked) => {
    let planIds = [];
    if (checked) {
      planIds = removeUsagePlans;
      endPointUsagePlans.map((row) => {
        planIds.push(row.id);
      });
      let plans = new Set(planIds);
      setRemoveUsagePlans([...plans]);
    } else {
      setRemoveUsagePlans(removeUsagePlans.filter((arr) => {}));
    }
  };

  const addAllCheckHandler = (checked) => {
    let planIds = addUsagePlans;
    if (checked) {
      unUsedUsagePlans.map((row) => {
        planIds.push(row.id);
      });
      let plans = new Set(planIds);
      setAddUsagePlans([...plans]);
    } else {
      setAddUsagePlans(addUsagePlans.filter((arr) => {}));
    }
  };

  const addCheckBoxHandler = (e) => {
    let plans = [];
    const targetId = Number(e.target.name);
    if (e.target.checked) {
      plans = [...addUsagePlans, targetId];
    } else {
      plans = addUsagePlans.filter((plan) => plan !== targetId);
    }
    plans = new Set(plans);
    setAddUsagePlans([...plans]);
  };

  const removeCheckBoxHandler = (e) => {
    let plans = [];
    const targetId = Number(e.target.name);
    if (e.target.checked) {
      plans = [...removeUsagePlans, targetId];
    } else {
      plans = removeUsagePlans.filter((plan) => plan !== targetId);
    }
    plans = new Set(plans);
    setRemoveUsagePlans([...plans]);
  };

  const searchClick = () => {
    let unUsedList =
      usagePlanList.filter(
        (row) =>
          endPointUsagePlans.findIndex(
            (usageplan) => usageplan.id === row.id
          ) === -1
      ) || [];

    unUsedList = unUsedList.filter(
      (row) =>
        String(row.id).includes(searchValue) ||
        String(row.name).includes(searchValue) ||
        String(row.desc).includes(searchValue)
    );
    unUsedList = new Set(unUsedList);
    setUnUsedUsagePlans([...unUsedList]);
    setAddUsagePlans([]);
  };

  return (
    <>
      <Table
        ths={usageplanThs}
        tds={endPointUsagePlans}
        count={endPointUsagePlans?.length || 0}
        countText={"연결된 사용량계획"}
        addClickFn={() => setMapModal(true)}
        removeClickFn={removeModalHandler}
        checkFn={removeCheckBoxHandler}
        allCheckHandler={(e) => removeAllCheckHandler(e.target.checked)}
        checkedList={removeUsagePlans || []}
        tableKey={"mapped-endpoint-usageplan"}
      />

      <Modal
        modalDP={mapModal}
        modalTitle="사용량계획 연결 추가"
        modalTxt="추가할 사용량계획을 선택해주세요"
        btnName="추가"
        infoItem1="기존 사용량계획"
        infoItem2="선택한 사용량계획"
        infoContent1={endPointUsagePlans?.length || 0}
        close={() => setMapModal(false)}
        checkFn={addCheckBoxHandler}
        confirmButtonClick={(e) => addHandler()}
        ths={usageplanThs}
        tds={unUsedUsagePlans}
        allCheckHandler={(e) => addAllCheckHandler(e.target.checked)}
        checkedList={addUsagePlans || []}
        changeSearchValue={(e) => setSearchValue(e.target.value)}
        searchClick={(e) => searchClick()}
        tableKey={"add-endpoint-usageplan"}
      />

      <DeleteModal
        display={deleteModal}
        close={() => setDeleteModal(!deleteModal)}
        modalTitle={"사용량 계획 연결제거"}
        modalTxt={"선택한 사용량 계획 연결을 제거하시겠습니까?"}
        boxContents={
          deleteModal
            ? [{ key: "선택한 사용량 계획", value: removeUsagePlans.length }]
            : []
        }
        announces={[
          "연결관계만 제거되며, 실제 엔드포인트는 삭제되지 않습니다.",
        ]}
        confirmButtonClick={removeQuery}
      />
    </>
  );
}

export default EndPointUsagePlanList;
