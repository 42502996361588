import React, { useCallback, useState } from "react";
import styled from "styled-components";

import MetaTag from "MetaTag";
import PageLayout from "components/templates/PageLayout";
import ListTable from "components/organisms/ListTable";
import { useProjectDelete, useProjectList } from "hooks/queries/useProjects";
import useAlert from "hooks/useAlert";
import DeleteModal from "components/templates/DeleteModal";
import AlertSign from "../../../components/organisms/AlertSign";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.div`
  box-sizing: border-box;
  padding: 40px 40px 40px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px;
  width: 100%;
  flex-grow: 1;
  transition: height 0.1s;
  background-color: #ffffff;
  border-radius: 32px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  width: 100%;
  min-height: 100px;
  background-color: #ffffff;
  box-sizing: border-box;
`;

const PageTitleText = styled.p`
  font-size: 20px;
  font-weight: 500;
  font-family: "Gmarket Sans";
  color: var(--color-DarkGreen);
`;

function ProjectList(props) {
  const { setAlertSign } = useAlertSign();
  const [params, setParameter] = useState({ search: "", page: 1 });
  const [deleteModal, setDeleteModal] = useState({ visible: false, obj: {} });

  let ths = [
    { key: "index", label: "번호" },
    { key: "name", label: "프로젝트 이름" },
    { key: "desc", label: "프로젝트 설명" },
    { key: "username", label: "관리자" },
    { key: "created_at", label: "생성일" },
    { key: "ns", label: "네임스페이스" },
    { key: "tz", label: "타임존" },
    { key: "endpoints", label: "엔드포인트" },
    { key: "apikeys", label: "API 키" },
    { key: "delete_icons", label: "삭제" },
  ];

  if (props.user.is_superuser) {
    ths = [
      { key: "index", label: "번호", flex: 1 },
      { key: "name", label: "프로젝트 이름", flex: 3 },
      { key: "desc", label: "프로젝트 설명", flex: 5 },
      { key: "username", label: "관리자", flex: 2 },
      { key: "created_at", label: "생성일", flex: 3 },
      { key: "ns", label: "네임스페이스", flex: 2 },
      { key: "tz", label: "타임존", flex: 2 },
      { key: "endpoints", label: "엔드포인트", flex: 1 },
      { key: "apikeys", label: "API 키", flex: 1 },
      { key: "delete_icons", label: "삭제", flex: 1 },
    ];
  }
  const { data, refetch } = useProjectList(params);

  const searchHandler = (e) => {
    params["search"] = e.target.value;
    params["page"] = 1;
  };

  const pageMoveHandler = (page) => {
    params["page"] = page;
    refetch(params);
  };

  const { mutate } = useProjectDelete();

  const deleteConfirm = (e, obj) => {
    e.stopPropagation();
    setDeleteModal({ visible: true, obj: obj });
  };
  const deleteHandler = useCallback(
    (obj) => {
      setDeleteModal({ visible: false, obj: {} });

      mutate(obj.id, {
        onSuccess: (data) => {
          setAlertSign("프로젝트가 삭제되었습니다.");
        },
      });
    },
    [mutate]
  );

  return (
    <>
      <MetaTag title="프로젝트 목록" />
      <PageLayout>
        <PageHeader>
          <PageTitleText>프로젝트 목록</PageTitleText>
        </PageHeader>
        <Container>
          <Box>
            <ListTable
              buttonName="프로젝트 생성"
              addButtonLink="/AddProject"
              detailLink="/Project"
              changeSearchValue={searchHandler}
              searchClick={(e) => refetch(params)}
              ths={ths}
              tds={data?.data.results}
              page={params.page}
              count={data?.data.count}
              pageMoveHandler={pageMoveHandler}
              deleteHandler={deleteConfirm}
              tableKey={"project-list"}
            />
          </Box>
        </Container>
      </PageLayout>
      <DeleteModal
        display={deleteModal.visible}
        close={() => setDeleteModal({ visible: false, obj: {} })}
        modalTitle={"프로젝트 삭제"}
        modalTxt={"프로젝트를 삭제하시겠습니까?"}
        boxContents={
          deleteModal
            ? [
                { key: "프로젝트 이름", value: deleteModal.obj.name },
                { key: "프로젝트 설명", value: deleteModal.obj.desc },
              ]
            : []
        }
        announces={["삭제 이후 복구가 불가능합니다."]}
        confirmButtonClick={() => deleteHandler(deleteModal.obj)}
      />
    </>
  );
}

export default ProjectList;
