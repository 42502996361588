import React from "react";
import styled from "styled-components";

import Layout from "components/organisms/ModalLayout";

import success from "../../../assets/svg/icon_greencheck.svg";
import fail from "../../../assets/svg/icon_redcross.svg";
import useAlert from "hooks/useAlert";

const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;

const TypeIcon = styled.div`
  margin-right: 16px;
  width: 20px;
  height: 20px;
  background-image: url(${(props) =>
    props.iconUrl === "fail" ? fail : success});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const ContentMessage = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: var(--color-BoldGreen);
`;

function Alert() {
  const { type, singleButton, title, message, display, removeAlert } =
    useAlert();

  return (
    <div style={{ zIndex: 11000, position: "relative" }}>
      <Layout
        modalDP={display}
        buttonDisplay={singleButton}
        close={removeAlert}
        type={type}
        width="500px"
        modalTitle={title}
        confirmButtonName="확인"
        defaultButtonName={type === "confirm" ? "취소" : "확인"}
        confirmButtonWidth="200px"
        defaultButtonWidth="200px"
      >
        <Row>
          <TypeIcon iconUrl={type}></TypeIcon>
          <ContentMessage>{message}</ContentMessage>
        </Row>
      </Layout>
    </div>
  );
}

export default Alert;
