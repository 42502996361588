import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Tab = styled.div`
  width: auto;
  cursor: pointer;

  &:first-child {
    margin-right: 40px;
  }

  .tabName {

    &:hover {
      color: var(--color-BoldGreen);
    }

`;

const TabName = styled.h5`
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Gmarket Sans';
  color: var(--color-GreyGreen);
  transition: all 100ms ease-in-out;
  ${(props) =>
    props.selected
      ? css`
          color: var(--color-BoldGreen);
        `
      : css`
          color: var(--color-GreyGreen);
        `}
  }
`;

const TabBar = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: var(--color-EmphasisGreen);
`;

function TabMenuDetail({ tabs, selectedTab, onClick }) {
  const [selected, setSelected] = useState(tabs[0].tabId);

  useEffect(() => {
    setSelected(selectedTab);
  }, [selectedTab]);

  const tabHandler = (e) => {
    setSelected(e.target.id);
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Container>
      {tabs.map((tab, idx) => (
        <Tab
          key={tab.tabId}
          onClick={tabHandler}
          name={tab.tabId}
          id={tab.tabId}
        >
          <TabName
            className="tabName"
            id={tab.tabId}
            selected={selected === tab.tabId}
            onClick={tabHandler}
            name={tab.tabId}
          >
            {tab.name}
          </TabName>
          {selected === tab.tabId && (
            <TabBar id={tab.tabId} onClick={(e) => e.stopPropagation()} />
          )}
        </Tab>
      ))}
    </Container>
  );
}

export default TabMenuDetail;
