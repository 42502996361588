import React from "react";
import styled from "styled-components";

import PageButton from "../../atoms/PaginationButton";

import BackIcon from "../../../assets/svg/icon_pageback.svg";
import NextIcon from "../../../assets/svg/icon_pagenext.svg";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 40px;
`;

const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: #eef8f7;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover {
    background-color: #b1e4d0;
  }
`;

const BackIconWrap = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.backicon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const NextButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: #eef8f7;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover {
    background-color: #cff0e4;
  }
`;

const NextIconWrap = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.nexticon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const NumWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 24px;
  width: fit-content;
  height: 40px;
  border-radius: 12px;
  background-color: #eef8f7;
`;

function Pagination({ count, page, pageMoveHandler }) {
  const lastPage = Math.ceil(count / 10);
  let targetPage = Math.ceil(page / 5) * 5 - 4;
  let nextPage = Math.ceil((page + 5) / 5) * 5 - 4;
  let prevPage = Math.ceil((page - 5) / 5) * 5 - 4;

  let pageGroup = [];
  for (var i = 0; i < 5; i++) {
    pageGroup.push(targetPage);
    if (targetPage === lastPage) {
      break;
    }
    targetPage += 1;
  }

  return (
    <Container>
      {lastPage > 1 && (
        <PaginationWrapper>
          <BackButton
            num={prevPage}
            disabled={prevPage < 0}
            onClick={(e) => !(prevPage < 0) && pageMoveHandler(prevPage)}
          >
            <BackIconWrap backicon={BackIcon}></BackIconWrap>
          </BackButton>
          <NumWrapper>
            {pageGroup
              .sort(function (a, b) {
                return a - b;
              })
              .map((pg, idx) => (
                <PageButton
                  key={idx}
                  num={pg}
                  selected={pg === page}
                  onClick={(e) => pageMoveHandler(pg)}
                />
              ))}
            {targetPage < lastPage && (
              <>
                <PageButton num="···" />
                <PageButton
                  num={lastPage}
                  onClick={(e) => pageMoveHandler(lastPage)}
                />
              </>
            )}
          </NumWrapper>
          <NextButton
            num={nextPage}
            disabled={nextPage > lastPage}
            onClick={(e) => !(nextPage > lastPage) && pageMoveHandler(nextPage)}
          >
            <NextIconWrap nexticon={NextIcon}></NextIconWrap>
          </NextButton>
        </PaginationWrapper>
      )}
    </Container>
  );
}

export default Pagination;
