import React from "react";
import { Helmet } from "react-helmet-async";

const MetaTag = (props) => {
  // props로 content 내용을 불러올 예정임
  return (
    <Helmet>
      <title>{props.title}</title>

      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title} />
      <meta property="og:site_name" content={props.title} />
      <meta property="og:description" content="게이트웨이 콘솔" />
      <meta property="og:image" content={props.imgsrc} />
      <meta property="og:url" content={props.url} />

      <link rel="canonical" href={props.url} />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
    </Helmet>
  );
};

export default MetaTag;
