import axios from "axios";
import { apiUrl } from "hooks/urls.js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAlert from "../useAlert";
import { useNavigate } from "react-router-dom";

const getProjectList = (params) => {
  return axios.get(apiUrl("project"), { params: params });
};

export const useProjectList = (params) => {
  return useQuery("projects", (e) => getProjectList(params), {
    refetchOnWindowFocus: false,
    suspense: true,
  });
};

const getProjectDetail = (id) => {
  return axios.get(apiUrl("project") + `/${id}`);
};

export const useProjectDetail = (params) => {
  return useQuery("project", (e) => getProjectDetail(params), {
    refetchOnWindowFocus: false,
    suspense: true,
  });
};

const createProject = (data) => {
  return axios.post(apiUrl("project"), data);
};

export const useProjectCreate = () => {
  const { setAlert } = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(createProject, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("projects");
      navigate("/Project");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

const updateProject = (data) => {
  return axios.put(apiUrl("project") + "/" + data.id, data);
};

export const useProjectUpdate = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useAlert();

  return useMutation(updateProject, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("projects");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

const deleteProject = (id) => {
  return axios.delete(apiUrl("project") + `/${id}`);
};

export const useProjectDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteProject, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("projects");
    },
  });
};
