import React from "react";
import styled from "styled-components";

import CheckIcon from "../../../assets/svg/icon_greencheck.svg";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 12px;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  width: 24px;
  height: 24px;
`;

const Icon = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${(props) => props.imgUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: var(--color-BoldGreen);
`;

function Unit({ infoText }) {
  return (
    <Container className="info_unit">
      <IconWrap>
        <Icon imgUrl={CheckIcon} />
      </IconWrap>
      <Text>{infoText}</Text>
    </Container>
  );
}

export default Unit;
