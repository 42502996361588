import React from "react";
import styled from "styled-components";

import SideMenu from "../../organisms/SideMenu";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const FullScreen = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const AvoidSideMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 240px;
  width: calc(100% - 240px);
  height: 100%;
  min-height: 100vh;
`;

function PageLayout({ children }) {
  return (
    <Container>
      <SideMenu />
      <FullScreen>
        <AvoidSideMenu>{children}</AvoidSideMenu>
      </FullScreen>
    </Container>
  );
}

export default PageLayout;
