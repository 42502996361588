import React from "react";
import styled from "styled-components";

import MetaTag from "../../../src/MetaTag";
import Header from "./Header";
import Footer from "./Footer";

import BGImage from "../../assets/png/index_background.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  background-image: url(${(props) => props.backImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 140px);
  background-image: url(${(props) => props.bgUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
`;

const MainContentContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20%;
  width: 100%;
  min-width: 1600px;
  height: 100%;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 120px;
`;

const MainText = styled.h1`
  font-size: 40px;
  font-weight: 600;
  font-family: "Gmarket Sans";
  line-height: 1.4;
  color: ${(props) => props.TxtColor};
  letter-spacing: 3px;

  &.undel_line {
    margin-top: 24px;
  }
`;

const SubText = styled.h3`
  margin-top: 80px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Gmarket Sans";
  color: var(--color-DarkGreen);
  letter-spacing: 2px;
`;

const JoinButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 200px;
  height: 60px;
  border-radius: 30px;
  background-color: var(--color-EmphasisGreen);
  transition: all 150ms;
  cursor: pointer;

  &:hover {
    background-color: #158153;
  }
`;

const ButtonText = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-family: "Gmarket Sans";
  color: #fff;
  letter-spacing: 1px;
`;

function Index({}) {
  return (
    <>
      <MetaTag title="맡김 게이트웨이" />
      <Header />
      <Container>
        <ContentContainer bgUrl={BGImage}>
          <MainContentContainer>
            <ContentWrapper>
              <MainText TxtColor="var(--color-RegularGreen)">
                고객과 서비스를
                <br />
                연결하는 문
              </MainText>
              <MainText
                className="undel_line"
                TxtColor="var(--color-DarkGreen)"
              >
                맡김 게이트웨이
              </MainText>
              <SubText>무료 베타테스트 진행중입니다</SubText>
              <a href={"mailto:member@datansoft.com?subject=<GATEWAY 문의>"}>
                <JoinButton>
                  <ButtonText>이메일로 참여 문의</ButtonText>
                </JoinButton>
              </a>
            </ContentWrapper>
          </MainContentContainer>
        </ContentContainer>
        <Footer />
      </Container>
    </>
  );
}

export default Index;
