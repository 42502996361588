/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  width: 168px;
  max-width: 168px;
  height: 40px;
  border-radius: 30px;
  background-color: #ffffff;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover {
    ${(props) =>
      props.selected
        ? css`
            background-color: var(--color-EmphasisGreen);
          `
        : css`
            background-color: #daf2ce;
          `}
  }
  ${(props) =>
    props.selected
      ? css`
          background-color: var(--color-NormalGreen);
        `
      : css`
          background-color: #ffffff;
        `}
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: 40px;
  height: 40px;
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.iconUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  & > img {
    ${(props) =>
      props.selected
        ? css`
            fill: #ffffff;
          `
        : css`
            fill: #89a0bf;
          `}
  }
`;

const TextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
`;

const Text = styled.p`
  font-size: 15px;
  font-weight: 600;

  ${(props) =>
    props.selected
      ? css`
          color: #ffffff;
        `
      : css`
          color: #89a0bf;
        `}
`;

function Item({ ...props }) {
  const { iconUrl, tabName, onClick, selected, id } = props;
  return (
    <Container selected={selected} onClick={onClick} id={id}>
      <IconWrap onClick={onClick} id={id}>
        <Icon onClick={onClick} id={id}>
          <img src={iconUrl} onClick={onClick} id={id} />
        </Icon>
      </IconWrap>
      <TextWrap onClick={onClick} id={id}>
        <Text selected={selected} onClick={onClick} id={id}>
          {tabName}
        </Text>
      </TextWrap>
    </Container>
  );
}

export default Item;
