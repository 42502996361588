import React, { useCallback, useState } from "react";
import styled from "styled-components";

import MetaTag from "MetaTag";
import AddModifyLayout from "components/templates/AddModifyLayout";
import InputwithTitle from "components/molecules/InputWithTitle";
import TextareawithTitle from "components/molecules/TextareaWithTitle";
import SelectwithTitle from "components/molecules/SelectWithTitle";
import { useProjectCreate } from "hooks/queries/useProjects";
import { projectValidation } from "utils/validation";
import PageLayout from "../../../components/templates/PageLayout";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-itmes: center;
  margin-bottom: 24px;
  width: 100%;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  box-sizing: border-box;
`;

const PageTitleText = styled.p`
  font-size: 20px;
  font-weight: 500;
  font-family: "Gmarket Sans";
  color: var(--color-DarkGreen);
`;

function AddProject() {
  const [project, setProject] = useState({
    name: "",
    desc: "",
    tz: "UTC",
    max_endpoints: 5,
  });
  const [validate, setValidate] = useState(false);
  const { setAlertSign } = useAlertSign();
  const { mutate } = useProjectCreate();

  const addHandler = useCallback(() => {
    mutate(project, {
      onSuccess: (data) => {
        setAlertSign("프로젝트 생성을 완료하였습니다.");
      },
    });
  }, [mutate]);

  const inputHandler = (e) => {
    let name = e.target.name;
    project[name] = e.target.value;
    setValidate(projectValidation(project));
  };

  return (
    <>
      <MetaTag title="프로젝트 생성" />
      <PageLayout>
        <PageHeader>
          <PageTitleText>프로젝트 생성</PageTitleText>
        </PageHeader>

        <AddModifyLayout
          pageTitle="프로젝트 추가"
          buttonName="추가"
          buttonClick={addHandler}
          buttonStatus={!validate}
          tabDP="none"
          breadCrumbs={[{ pageName: "프로젝트 생성", url: "" }]}
        >
          <Container>
            <Row>
              <InputwithTitle
                width="100%"
                inputTitle="프로젝트 이름"
                placeholder="프로젝트 이름을 입력해주세요"
                tooltipTxt="프로젝트 명칭"
                value={project.name}
                name={"name"}
                onChange={inputHandler}
                maxLength={50}
              />
            </Row>
            <Row>
              <TextareawithTitle
                width="100%"
                areaHeight="120px"
                EsnSignDp="none"
                inputTitle="프로젝트 설명"
                placeholder="프로젝트 설명을 입력해주세요"
                tooltipTxt="프로젝트 상세 내용"
                value={project.desc}
                name={"desc"}
                onChange={inputHandler}
                maxLength={500}
              />
            </Row>
            <Row>
              <InputwithTitle
                width="100%"
                EsnSignDp="none"
                inputTitle="네임 스페이스"
                placeholder="자동으로 생성됩니다"
                tooltipTxt={`요청 URL에서 ${process.env.REACT_APP_PROXY_BASE_URL}/<namespace>/<endpoint> 형태로 활용됩니다. 임의의 영문/숫자의 조합으로 시스템이 자동생성합니다.`}
                disabled={true}
              />
            </Row>
            <Row>
              <SelectwithTitle
                width="calc(50% - 10px)"
                selectTitle="타임존"
                tooltipTxt="호출량을 집계할 기준시간대"
                name={"tz"}
                value={project.tz}
                onChange={inputHandler}
                items={["UTC", "Asia/Seoul"]}
              />
              <InputwithTitle
                width="calc(50% - 10px)"
                EsnSignDp="none"
                inputTitle="최대 엔드포인트"
                placeholder="5"
                name={"max_endpoints"}
                tooltipTxt="연결 가능한 최대 URL 개수"
                value={project.maxEndpoint}
                onChange={inputHandler}
                type={"number"}
                disabled={true}
              />
            </Row>
          </Container>
        </AddModifyLayout>
      </PageLayout>
    </>
  );
}

export default AddProject;
