import React from "react";
import styled from "styled-components";

import Input from "../../atoms/Input";
import SearchButton from "../../atoms/SearchButton";

const Container = styled.div`
  position: relative;
  width: 400px;
  height: 60px;
`;

function SearchBar({ placeholder, onChange, value, searchClick }) {
  return (
    <Container>
      <Input
        placeholder={placeholder}
        width="400px"
        value={value}
        onChange={onChange}
        onEnterKeyPress={searchClick}
        searchInput={true}
      />
      <SearchButton onClick={searchClick} />
    </Container>
  );
}

export default SearchBar;
