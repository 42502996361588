import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { AlertProvider } from "components/templates/Alert/AlertContext";
import Loading from "./page/Index/Loading";
import { AlertSignProvider } from "./components/templates/AlertSign/AlertSignContext";

const queryClient = new QueryClient({});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <AlertProvider>
          <AlertSignProvider>
            {/*<ReactQueryDevtools initialIsOpen={true}/>*/}
            <Suspense fallback={<Loading />}>
              <App />
            </Suspense>
          </AlertSignProvider>
        </AlertProvider>
      </HelmetProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
