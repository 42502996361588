import React, { createContext, useState } from "react";

const initialState = {
  text: "",
};

const AlertSignContext = createContext({
  ...initialState,
  setAlert: () => {},
  removeAlert: () => {},
});

export function AlertSignProvider({ children }) {
  const [display, setDisplay] = useState(false);
  const [text, setText] = useState("");

  const setAlertSign = (texts) => {
    setText(texts);
    setDisplay(true);
    setTimeout(function () {
      setDisplay(false);
    }, 3000);
  };

  const removeAlert = () => {
    setDisplay(false);
  };

  const contextValue = {
    text,
    display,
    setAlertSign,
    removeAlert,
  };

  return (
    <AlertSignContext.Provider value={contextValue}>
      {children}
    </AlertSignContext.Provider>
  );
}

export default AlertSignContext;
