import React from "react";
import styled from "styled-components";

import ModalTitle from "../../atoms/ModalTitle";

import CrossIcon from "../../../assets/svg/cross_mark.svg";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px 40px;
  width: calc(100% - 80px);
  height: auto;
  border-bottom: solid 1px #e6e6e6;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const CrossIconWrap = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.iconUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

function ModalHeader({ modalTitle, closeIconClick }) {
  return (
    <Container>
      <ModalTitle modalTitle={modalTitle} />
      <CloseButton>
        <CrossIconWrap iconUrl={CrossIcon} onClick={closeIconClick} />
      </CloseButton>
    </Container>
  );
}

export default ModalHeader;
