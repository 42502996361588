import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BackIcon } from "../../../assets/svg/icon_back.svg";

const Container = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  width: 48px;
  height: 48px;
  border-radius: 16px;
  background-color: #89a0bf;
  transition: all 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #4f698d;
  }
`;

const ImgWrap = styled.div`
  position: relative;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;

function BackButton({ link }) {
  const navigate = useNavigate();
  return (
    <Container onClick={() => (link ? link() : navigate(-1))}>
      <ImgWrap>
        <BackIcon />
      </ImgWrap>
    </Container>
  );
}

export default BackButton;
