import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import MetaTag from "MetaTag";
import PageLayout from "components/templates/PageLayout";
import ProjectHeader from "components/organisms/ProjectHeader";
import AddModifyLayout from "components/templates/AddModifyLayout";
import InputwithTitle from "components/molecules/InputWithTitle";
import TextareawithTitle from "components/molecules/TextareaWithTitle";
import SelectwithTitle from "components/molecules/SelectWithTitle";
import EmphasisTitle from "components/atoms/EmphasisTitle";
import Tooltip from "components/atoms/Tooltip";
import CheckboxWithText from "components/molecules/CheckboxWithText";
import { useNavigate, useParams } from "react-router-dom";
import useAlert from "../../../hooks/useAlert";
import { usageplanValidation } from "../../../utils/validation";
import {
  useUsagePlanDetail,
  useUsagePlanUpdate,
} from "../../../hooks/queries/useUsagePlans";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-itmes: center;
  margin-bottom: 24px;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
  width: 100%;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > h2 {
    margin-right: 8px;
  }
`;

function ModifyUsagePlan() {
  const navigate = useNavigate();
  const { setAlertSign } = useAlertSign();
  const { projectId, id } = useParams();

  const [usagePlan, setUsagePlan] = useState({
    id: 0,
    name: "",
    desc: "",
    quota_enabled: false,
    quota_period_type: "h",
    quota_amount: 0,
    throttling_enabled: false,
    throttling_period_msec: 0,
    throttling_amount: 0,
    projectId: projectId,
  });

  const [validate, setValidate] = useState(false);
  const { data } = useUsagePlanDetail({ id: id, projectId: projectId });

  useEffect(() => {
    if (data) {
      setUsagePlan(Object.assign(data.data, { projectId: projectId }));
    }
  }, [data]);

  const { mutate } = useUsagePlanUpdate();
  const updateHandler = useCallback(() => {
    mutate(usagePlan, {
      onSuccess: (data) => {
        navigate(`/Project/${projectId}/UsagePlan/${id}`);
        setAlertSign("사용량 계획 수정이 완료되었습니다.");
      },
    });
  }, [usagePlan]);

  const inputHandler = (e) => {
    let name = e.target.name;
    if (e.target.type === "checkbox") {
      usagePlan[name] = e.target.checked;
      setUsagePlan({ ...usagePlan, [name]: e.target.checked });
    } else {
      usagePlan[name] = e.target.value;
      setUsagePlan({ ...usagePlan, [name]: e.target.value });
    }
    setValidate(usageplanValidation(usagePlan));
  };
  return (
    <>
      <MetaTag title="사용량계획 수정" />
      <PageLayout>
        <ProjectHeader />
        <AddModifyLayout
          pageTitle="사용량계획 수정"
          buttonName="수정"
          buttonClick={updateHandler}
          buttonStatus={!validate}
          breadCrumbs={[
            {
              pageName: "사용량계획 관리",
              url: { url: `/Project/${projectId}`, state: "tab-usageplan" },
            },
            { pageName: "사용량계획 수정", url: "" },
          ]}
        >
          <Container>
            <Row>
              <InputwithTitle
                width="100%"
                inputTitle="사용량계획 이름"
                placeholder="사용량계획 이름을 입력해주세요"
                tooltipTxt="사용량계획 명칭"
                value={usagePlan.name}
                name={"name"}
                onChange={inputHandler}
                maxLength={50}
              />
            </Row>
            <Row>
              <TextareawithTitle
                width="100%"
                areaHeight="120px"
                EsnSignDp="none"
                inputTitle="사용량계획 설명"
                placeholder="사용량계획 설명을 입력해주세요"
                tooltipTxt="사용량계획 상세 내용"
                value={usagePlan.desc}
                name={"desc"}
                onChange={inputHandler}
                maxLength={500}
              />
            </Row>
            <Column>
              <Row>
                <LeftSide>
                  <EmphasisTitle barTxt="호출한도 설정" />
                  <Tooltip tooltipTxt="특정 API 키가 시간/일/월 단위로 호출할 수 있는 한도를 설정합니다." />
                </LeftSide>
                <CheckboxWithText
                  checkTxt="사용"
                  value={usagePlan.quota_enabled}
                  onChange={inputHandler}
                  name={"quota_enabled"}
                />
              </Row>
              <Row>
                <SelectwithTitle
                  width="calc(50% - 10px)"
                  selectTitle="호출단위시간"
                  tooltipDP={false}
                  value={usagePlan.quota_period_type}
                  onChange={inputHandler}
                  items={[
                    { id: "h", name: "시간" },
                    { id: "d", name: "일" },
                    { id: "m", name: "월" },
                  ]}
                  EsnSignDp={"none"}
                  name={"quota_period_type"}
                  disabled={!usagePlan.quota_enabled}
                />
                <InputwithTitle
                  width="calc(50% - 10px)"
                  inputTitle="최대호출량(건)"
                  placeholder="최대호출량을 입력해주세요"
                  tooltipDP={false}
                  value={usagePlan.quota_amount}
                  onChange={inputHandler}
                  items={["h", "d", "m"]}
                  name={"quota_amount"}
                  disabled={!usagePlan.quota_enabled}
                  type={"number"}
                />
              </Row>
            </Column>
            <Column>
              <Row>
                <LeftSide>
                  <EmphasisTitle barTxt="부하방지 설정" />
                  <Tooltip
                    tooltipTxt="특정 API키의 호출에 대한 부하 판단 기준을 설정합니다. (예: 1,000ms 당 1 회)
부하라고 판단될 경우, 리셋될 때까지 호출을 방지합니다.
"
                  />
                </LeftSide>
                <CheckboxWithText
                  checkTxt="사용"
                  value={usagePlan.throttling_enabled}
                  onChange={inputHandler}
                  name={"throttling_enabled"}
                />
              </Row>
              <Row>
                <InputwithTitle
                  width="calc(50% - 10px)"
                  inputTitle="부하단위시간(ms)"
                  placeholder="부하단위시간을 입력해주세요"
                  tooltipDP={false}
                  value={usagePlan.throttling_period_msec}
                  name={"throttling_period_msec"}
                  onChange={inputHandler}
                  maxLength={50}
                  type={"number"}
                  disabled={!usagePlan.throttling_enabled}
                />
                <InputwithTitle
                  width="calc(50% - 10px)"
                  inputTitle="최대부하량(건)"
                  placeholder="최대부하량을 입력해주세요"
                  tooltipDP={false}
                  value={usagePlan.throttling_amount}
                  name={"throttling_amount"}
                  onChange={inputHandler}
                  maxLength={50}
                  type={"number"}
                  disabled={!usagePlan.throttling_enabled}
                />
              </Row>
            </Column>
          </Container>
        </AddModifyLayout>
      </PageLayout>
    </>
  );
}

export default ModifyUsagePlan;
