import React from "react";
import styled from "styled-components";

const Container = styled.h2`
  font-size: 20px;
  font-weight: 500;
  font-family: "Gmarket Sans";
  color: var(--color-DarkGreen);
  padding-left: 12px;
  border-left: 4px solid var(--color-EmphasisGreen);
`;

function EmphasisTitle({ barTxt }) {
  return <Container>{barTxt}</Container>;
}

export default EmphasisTitle;
