import axios from "axios";
import { apiUrl } from "hooks/urls.js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAlert from "../useAlert";

const getEndPointUsagePlans = (params) => {
  return axios.get(
    `${apiUrl("project")}/${params.projectId}/endpoints/${
      params.id
    }/usageplans`,
    { params: params }
  );
};

const createEndPointUsagePlan = (data) => {
  return axios.post(
    `${apiUrl("project")}/${data.projectId}/endpoints/${
      data.apiendpoint
    }/usageplans`,
    data
  );
};

const deleteEndPointUsagePlan = (params) => {
  return axios.delete(
    `${apiUrl("project")}/${params.projectId}/endpoints/${
      params.apiendpoint
    }/usageplans/${params.apiusageplan}`
  );
};

export const useEndPointUsagePlans = (params) => {
  return useQuery("EndPointUsagePlans", (e) => getEndPointUsagePlans(params), {
    refetchOnWindowFocus: false,
  });
};

export const useEndPointUsagePlanCreate = () => {
  const { setAlert } = useAlert();
  const queryClient = useQueryClient();

  return useMutation(createEndPointUsagePlan, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("EndPointUsagePlans");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

export const useEndPointUsagePlanDelete = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useAlert();

  return useMutation(deleteEndPointUsagePlan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("EndPointUsagePlans");
      queryClient.invalidateQueries("usageplans");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};
