import React, { useId } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import Td from "../../atoms/Td";
import CheckBox from "../../atoms/CheckBox";

import EmptyImg from "../../../assets/png/blank_image.png";

const Container = styled.tbody`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;
  width: 100%;
  height: ${(props) => props.height};
  max-height: ${(props) => props.height};
  border-radius: 12px;
  background-color: #ffffff;
  overflow: auto;

  & > a {
    width: 100%;
  }
  ${(props) =>
    props.noContent &&
    css`
      background: #eef5f8;
    `}
`;

const CheckBoxContains = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  text-align: center;
`;

const Tr = styled.tr`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  min-height: 52px;
  border-bottom: solid 1px #e6e6e6;
  border-radius: 12px;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 120ms;
  &:hover {
    background-color: var(--color-BgBlue);
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const EmptyImage = styled.div`
  width: 200px;
  height: 140px;
  background-image: url(${(props) => props.emptyImg});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const NullMessage = styled.p`
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Gmarket sans";
  color: var(--color-IconBlue);
`;

function TableBody({
  tableHeight,
  href,
  tds = [],
  ths,
  deleteHandler,
  name,
  checkFn,
  checkedList = [],
  aggregateModalHandler,
  page,
  count,
  tableKey,
  copyDP,
}) {
  const navigate = useNavigate();
  const id = useId();
  const rowClickHandler = (id) => {
    if (href) {
      const splitedHref = href.split("/") || [];
      if (href === "/Project") {
        navigate(href + `/${id}`);
      } else if (splitedHref[splitedHref.length - 1] === "Endpoint") {
        navigate(href + `/${id}`);
      } else if (splitedHref[splitedHref.length - 1] === "UsagePlan") {
        navigate(href + `/${id}`);
      } else if (splitedHref[splitedHref.length - 1] === "APIKey") {
        navigate(href + `/${id}`);
      } else {
        return false;
      }
    }
  };
  const datas = tds.map((row, index) =>
    page
      ? { ...row, index: count - index - (page - 1) * 10 }
      : {
          ...row,
          index: tds.length - index,
        }
  );

  return (
    <Container height={tableHeight} noContent={tds.length === 0}>
      {tds.length === 0 && (
        <EmptyBox>
          <EmptyImage emptyImg={EmptyImg}></EmptyImage>
          <NullMessage>목록이 비어있습니다</NullMessage>
        </EmptyBox>
      )}
      {(datas || []).map((td) => (
        <Tr
          onClick={
            checkFn
              ? (e) =>
                  checkFn({
                    target: {
                      name: td.id,
                      checked: !checkedList.includes(td.id),
                    },
                  })
              : (e) => rowClickHandler(td.id || undefined)
          }
          key={`${id}-${td.id}`}
          name={td["id"]}
        >
          {ths.map((th) =>
            th.key === "checkbox" ? (
              <CheckBoxContains>
                <CheckBox
                  name={td["id"]}
                  value={checkedList.includes(td.id)}
                  onChange={checkFn}
                  key={tableKey + td.id + "checkbox"}
                />
              </CheckBoxContains>
            ) : (
              <Td
                key={tableKey + td.id + th.key}
                tdText={td[th.key]}
                tdFlex={th?.flex}
                type={th.key}
                obj={td}
                deleteHandler={deleteHandler}
                aggregateModalHandler={aggregateModalHandler}
                copyDP={tableKey === "apikey-list" && th.key === "value"}
              />
            )
          )}
        </Tr>
      ))}
    </Container>
  );
}

export default TableBody;
