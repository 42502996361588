import React, { useCallback, useState } from "react";
import styled from "styled-components";
import ListTable from "components/organisms/ListTable";
import DeleteModal from "components/templates/DeleteModal";
import { useEndpointDelete, useEndpointList } from "hooks/queries/useEndpoints";
import useAlertSign from "../../../hooks/useAlertSign";

function EndPointList({ projectId }) {
  const { setAlertSign } = useAlertSign();
  const [params, setParameter] = useState({
    search: "",
    page: 1,
    projectId: projectId,
  });
  const [deleteModal, setDeleteModal] = useState({ visible: false, obj: {} });

  const ths = [
    { key: "index", label: "번호", flex: 1 },
    { key: "name", label: "엔드포인트 이름", flex: 3 },
    { key: "desc", label: "엔드포인트 설명", flex: 5 },
    { key: "method", label: "Method", flex: 2 },
    { key: "url_src_path", label: "요청 URL", flex: 4 },
    { key: "url_tgt_full", label: "타겟 URL", flex: 4 },
    { key: "created_at", label: "생성일", flex: 2 },
    { key: "delete_icons", label: "삭제", flex: 1 },
  ];

  const { data, refetch } = useEndpointList(params);

  const searchHandler = (e) => {
    params["search"] = e.target.value;
    params["page"] = 1;
  };

  const pageMoveHandler = (page) => {
    params["page"] = page;
    refetch(params);
  };

  const deleteConfirm = (e, obj) => {
    e.stopPropagation();
    setDeleteModal({ visible: true, obj: obj });
  };
  const { mutate } = useEndpointDelete();
  const deleteHandler = useCallback(
    (obj) => {
      const projectIncludeObj = Object.assign({}, obj, {
        projectId: projectId,
      });
      setDeleteModal({ visible: false, obj: {} });

      mutate(projectIncludeObj, {
        onSuccess: (data) => {
          setAlertSign("엔드포인트가 삭제되었습니다.");
        },
      });
    },
    [mutate]
  );

  return (
    <>
      <ListTable
        buttonName="엔드포인트 생성"
        addButtonLink={`/Project/${projectId}/AddEndpoint`}
        detailLink={`/Project/${projectId}/Endpoint`}
        changeSearchValue={searchHandler}
        searchClick={(e) => refetch(params)}
        ths={ths}
        tds={data?.data.results}
        page={params.page}
        count={data?.data.count}
        pageMoveHandler={pageMoveHandler}
        deleteHandler={deleteConfirm}
        tableKey={"endpoint-list"}
        tabState={"tab-endpoint"}
      />
      <DeleteModal
        display={deleteModal.visible}
        close={() => setDeleteModal({ visible: false, obj: {} })}
        modalTitle={"엔드포인트 삭제"}
        modalTxt={"엔드포인트를 삭제하시겠습니까?"}
        boxContents={
          deleteModal
            ? [
                { key: "엔드포인트 이름", value: deleteModal.obj.name },
                { key: "엔드포인트 설명", value: deleteModal.obj.desc },
                {
                  key: "연결된 사용량 계획",
                  value: deleteModal.obj.usageplan_count,
                },
              ]
            : []
        }
        announces={[
          "삭제 이후 복구가 불가능합니다.",
          "삭제 시 연결된 항목에서도 함께 제거됩니다.",
        ]}
        confirmButtonClick={() => deleteHandler(deleteModal.obj)}
      />
    </>
  );
}

export default EndPointList;
