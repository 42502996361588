import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  height: 48px;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 120ms;
  &:hover {
    background-color: var(--color-EmphasisGreen);
  }
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  height: 48px;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${(props) => props.iconUrl});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const MenuName = styled.p`
  position: relative;
  top: 3px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Gmarket sans";
  color: #ffffff;
`;

function MenuContents({ status, iconUrl, btnTxt, onClick }) {
  return (
    <Container status={status} onClick={onClick}>
      <IconWrap>
        <Icon iconUrl={iconUrl}></Icon>
      </IconWrap>
      <MenuName>{btnTxt}</MenuName>
    </Container>
  );
}

export default MenuContents;
