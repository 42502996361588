import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";

import MetaTag from "MetaTag";
import ProjectHeader from "components/organisms/ProjectHeader";
import OptionButton from "components/molecules/ProjectButton";
import BackButton from "components/atoms/BackButton";
import PageLayout from "components/templates/PageLayout";
import PageTitle from "components/organisms/PageTitle";
import DetailBox from "components/organisms/DetailBox";
import DetailInfoItem from "components/molecules/DetailInfoItem";
import TabMenu from "components/molecules/TabMenu";
import { useLocation } from "react-router-dom";

import TabIcon1 from "assets/svg/tab_icon_1.svg";
import TabIcon2 from "assets/svg/tab_icon_2.svg";
import TabIcon3 from "assets/svg/tab_icon_3.svg";
import ModifyIcon from "assets/svg/icon_modify.svg";
import DeleteIcon from "assets/svg/icon_delete.svg";
import {
  useEndpointDelete,
  useEndpointDetail,
} from "hooks/queries/useEndpoints";
import DeleteModal from "components/templates/DeleteModal";
import useAlert from "hooks/useAlert";
import TabBox from "components/organisms/TabBox";
import EndPointUsagePlanList from "./EndPointUsagePlanList";
import EndPointApiKeyList from "./EndPointApiKeyList";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
  padding: 0 40px 40px 40px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ContentsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  box-sizing: border-box;
  margin-top: 24px;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
`;

const ColumnSide = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.h5`
  margin-bottom: 4px;
  font-size: 15px;
  font-weught: 400;
  color: var(--color-GreyGreen);
`;

const EndpointName = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: "Gmarket Sans";
`;

const BoxButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;

const EndpointDescription = styled.p`
  width: 100%;
  max-width: 100%;
`;

const InfoWrap = styled.div`
  displat: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  width: 100%;
`;

function EndpointDetail() {
  const { projectId, id } = useParams();
  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();

  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = useState({ visible: false, obj: {} });
  const [selectedTab, setSelectedTab] = useState("usageplan");

  const { data } = useEndpointDetail({ id: id, projectId: projectId });
  const endpoint = data?.data;

  const deleteConfirm = (e, obj) => {
    e.stopPropagation();
    setDeleteModal({ visible: true, obj: obj });
  };

  const { mutate } = useEndpointDelete();
  const deleteHandler = useCallback(
    (obj) => {
      const projectIncludeObj = Object.assign({}, obj, {
        projectId: projectId,
      });
      setDeleteModal({ visible: false, obj: {} });

      mutate(projectIncludeObj, {
        onSuccess: (data) => {
          navigate(`/Project/${projectId}`, { state: "tab-endpoint" });
          setAlertSign("엔드포인트 삭제가 완료되었습니다.");
        },
      });
    },
    [mutate]
  );

  const [tabState, setTabState] = useState("tab-endpoint");

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setTabState(state);
    }
  }, []);

  // tab items
  const tabs = [
    {
      id: "tab-endpoint",
      iconUrl: TabIcon1,
      tabName: "엔드포인트 관리",
    },
    {
      id: "tab-usageplan",
      iconUrl: TabIcon2,
      tabName: "사용량계획 관리",
    },
    {
      id: "tab-apikey",
      iconUrl: TabIcon3,
      tabName: "API 키 관리",
    },
  ];

  return (
    <>
      <MetaTag title="엔드포인트 상세정보" />
      <PageLayout>
        <ProjectHeader />

        <Container>
          <Header>
            <HeaderLeftSide>
              <BackButton
                link={(e) =>
                  navigate(`/Project/${projectId}`, { state: "tab-endpoint" })
                }
              />
              <PageTitle
                pageTitle="엔드포인트 상세정보"
                breadCrumbs={[
                  {
                    pageName: "엔드포인트 관리",
                    url: {
                      url: `/Project/${projectId}`,
                      state: "tab-endpoint",
                    },
                  },
                  { pageName: "엔드포인트 상세정보", url: "" },
                ]}
              />
            </HeaderLeftSide>
            <TabMenu
              tabs={tabs}
              tabSelectedFn={(e) =>
                navigate(`/Project/${projectId}`, { state: e.target.id })
              }
              selected={"tab-endpoint"}
            />
          </Header>

          <ContentsWrap>
            <DetailBox href="/ModifyEndpoint">
              <HeaderRow>
                <ColumnSide>
                  <ContentTitle>엔드포인트 이름</ContentTitle>
                  <EndpointName>{endpoint?.name}</EndpointName>
                </ColumnSide>
                <BoxButtonWrap>
                  <Link
                    to={`/Project/${projectId}/ModifyEndpoint/${id}`}
                    state={"tab-endpoint"}
                  >
                    <OptionButton svgUrl={ModifyIcon} />
                  </Link>
                  <OptionButton
                    svgUrl={DeleteIcon}
                    margin="0 0 0 16px"
                    onClick={(e) => deleteConfirm(e, endpoint)}
                  />
                </BoxButtonWrap>
              </HeaderRow>
              <ColumnSide>
                <ContentTitle>엔드포인트 설명</ContentTitle>
                <EndpointDescription>{endpoint?.desc}</EndpointDescription>
              </ColumnSide>
              <InfoWrap>
                <DetailInfoItem
                  contentName="요청 URL"
                  contentText={endpoint?.url_src_path}
                />
                <DetailInfoItem
                  margin="24px 0 24px 0"
                  contentName="타겟 URL"
                  contentText={endpoint?.url_tgt_full}
                />
                <DetailInfoItem
                  contentName="Method 방식"
                  contentText={endpoint?.method}
                />
              </InfoWrap>
            </DetailBox>

            <TabBox
              tabs={[
                { name: "사용량계획", tabId: "usageplan" },
                { name: "API 키", tabId: "apikey" },
              ]}
              selectedTab={selectedTab}
              onClick={(e) => setSelectedTab(e.target.id)}
            >
              {selectedTab === "usageplan" ? (
                <EndPointUsagePlanList />
              ) : (
                <EndPointApiKeyList />
              )}
            </TabBox>
          </ContentsWrap>
        </Container>

        <DeleteModal
          display={deleteModal.visible}
          close={() => setDeleteModal({ visible: false, obj: {} })}
          modalTitle={"엔드포인트 삭제"}
          modalTxt={"엔드포인트를 삭제하시겠습니까?"}
          boxContents={
            deleteModal
              ? [
                  { key: "엔드포인트 이름", value: deleteModal.obj.name },
                  { key: "엔드포인트 설명", value: deleteModal.obj.desc },
                  {
                    key: "연결된 사용량 계획",
                    value: deleteModal.obj.usageplan_count,
                  },
                ]
              : []
          }
          announces={[
            "삭제 이후 복구가 불가능합니다.",
            "삭제 시 연결된 항목에서도 함께 제거됩니다.",
          ]}
          confirmButtonClick={() => deleteHandler(deleteModal.obj)}
        />
      </PageLayout>
    </>
  );
}

export default EndpointDetail;
