import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import PageTitle from "../../organisms/PageTitle";
import Button from "../../atoms/Button";
import BackButton from "../../atoms/BackButton";
import TabMenu from "components/molecules/TabMenu";
import { useLocation, useParams } from "react-router-dom";

import TabIcon1 from "assets/svg/tab_icon_1.svg";
import TabIcon2 from "assets/svg/tab_icon_2.svg";
import TabIcon3 from "assets/svg/tab_icon_3.svg";

const Container = styled.div`
  box-sizing: border-box;
  margin-top: 24px;
  padding: 0 40px 40px 40px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
`;

const HeaderLeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Box = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  width: 720px;
  height: calc(100% - 72px);
  background-color: #ffffff;
  border-radius: 32px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 120px;
`;

const ChildrenWrap = styled.div`
  width: 100%;
  height: auto;
`;

function AddModifyLayout({
  pageTitle,
  buttonName,
  children,
  buttonClick,
  buttonStatus,
  tabDP,
  breadCrumbs,
}) {
  const navigate = useNavigate();

  const [tabState, setTabState] = useState("tab-endpoint");

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setTabState(state);
    }
  }, []);

  // 프로젝트 ID 값 저장
  const { projectId } = useParams();

  // tab items
  const tabs = [
    {
      id: "tab-endpoint",
      iconUrl: TabIcon1,
      tabName: "엔드포인트 관리",
    },
    {
      id: "tab-usageplan",
      iconUrl: TabIcon2,
      tabName: "사용량계획 관리",
    },
    {
      id: "tab-apikey",
      iconUrl: TabIcon3,
      tabName: "API 키 관리",
    },
  ];
  return (
    <Container>
      <Header>
        <HeaderLeftSide>
          <BackButton />
          <PageTitle pageTitle={pageTitle} breadCrumbs={breadCrumbs} />
        </HeaderLeftSide>
        <TabMenu
          tabDP={tabDP}
          tabs={tabs}
          tabSelectedFn={(e) =>
            navigate(`/Project/${projectId}`, { state: e.target.id })
          }
          selected={tabState}
        />
      </Header>
      <Box>
        <ChildrenWrap>{children}</ChildrenWrap>
        <ButtonWrap>
          <Button
            width="calc(50% - 10px)"
            bgColor="var(--color-NormalGreen)"
            hvColor="var(--color-EmphasisGreen)"
            txtColor="#FFFFFF"
            disabled={buttonStatus}
            buttonName={buttonName}
            onClick={buttonClick}
          />
          <Button
            width="calc(50% - 10px)"
            bgColor="var(--color-BgBlue)"
            hvColor="#D8E8EF"
            txtColor="var(--color-RegularGreen)"
            buttonName="취소"
            onClick={() => {
              navigate(-1);
            }}
          />
        </ButtonWrap>
      </Box>
    </Container>
  );
}

export default AddModifyLayout;
