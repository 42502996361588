import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./App.scss";
import Login from "./page/Login";
import AlertSign from "./components/templates/AlertSign";
// Project
import ProjectList from "./page/Project/ProjectList";
import ProjectDetail from "./page/Project/ProjectDetail";
import AddProject from "./page/Project/AddProject";
import ModifyProject from "./page/Project/ModifyProject";

// EndPoint
import EndpointDetail from "./page/EndPoint/EndpointDetail";
import AddEndpoint from "./page/EndPoint/AddEndpoint";
import ModifyEndpoint from "./page/EndPoint/ModifyEndpoint";

// UsagePlan
import UsagePlanDetail from "./page/UsagePlan/UsagePlanDetail";
import AddUsagePlan from "./page/UsagePlan/AddUsagePlan";
import ModifyUsagePlan from "./page/UsagePlan/ModifyUsagePlan";

// ApiKey
import APIKeyDetail from "./page/ApiKey/APIKeyDetail";
import AddAPIKey from "./page/ApiKey/AddAPIKey";
import ModifyAPIKey from "./page/ApiKey/ModifyAPIKey";

import { userCheck } from "hooks/auth";
import { useQuery } from "react-query";
import Alert from "./components/templates/Alert";
import Index from "./page/Index";
import Loading from "./page/Index/Loading";
import AlertSigns from "./components/templates/AlertSign";

function App() {
  const [userStatus, setUserStatus] = useState({
    is_authenticated: undefined,
    is_superuser: false,
    username: "",
  });
  useQuery("user", userCheck, {
    retry: 1,
    onSuccess: (data) => {
      setUserStatus({
        is_authenticated: data.is_authenticated,
        is_superuser: data.is_superuser,
        username: data.username,
      });
    },
    onError: (data) => {
      if (data.response.status === 401) {
        console.clear();
      }
      setUserStatus({
        is_authenticated: false,
        is_superuser: false,
        username: "",
      });
    },
  });
  return (
    <>
      <Helmet>
        <title>게이트웨이 콘솔</title>
      </Helmet>
      <Alert />
      <AlertSigns />
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              userStatus.is_authenticated === undefined ? (
                <Loading />
              ) : userStatus.is_authenticated ? (
                <ProjectList user={userStatus} />
              ) : (
                <Index />
              )
            }
          />
          <Route exact path="/Login" element={<Login />} />

          {userStatus.is_authenticated && (
            <>
              <Route
                exact
                path={"/Project"}
                element={<ProjectList user={userStatus} />}
              />
              <Route exact path={"/AddProject"} element={<AddProject />} />
              <Route
                exact
                path={"/ModifyProject/:projectId"}
                element={<ModifyProject />}
              />
              <Route
                exact
                path={"/Project/:projectId"}
                element={<ProjectDetail />}
              />

              <Route
                exact
                path={"/Project/:projectId/Endpoint/:id"}
                element={<EndpointDetail />}
              />
              <Route
                exact
                path={"/Project/:projectId/AddEndpoint"}
                element={<AddEndpoint />}
              />
              <Route
                exact
                path={"/Project/:projectId/ModifyEndpoint/:id"}
                element={<ModifyEndpoint />}
              />

              <Route
                exact
                path={"/Project/:projectId/UsagePlan/:id"}
                element={<UsagePlanDetail />}
              />
              <Route
                exact
                path={"/Project/:projectId/AddUsagePlan"}
                element={<AddUsagePlan />}
              />
              <Route
                exact
                path={"/Project/:projectId/ModifyUsagePlan/:id"}
                element={<ModifyUsagePlan />}
              />

              <Route
                exact
                path={"/Project/:projectId/APIKey/:id"}
                element={<APIKeyDetail />}
              />
              <Route
                exact
                path={"/Project/:projectId/AddAPIKey"}
                element={<AddAPIKey />}
              />
              <Route
                exact
                path={"/Project/:projectId/ModifyAPIKey/:id"}
                element={<ModifyAPIKey />}
              />
              <Route path="*" element={<h1>404 not found</h1>} />
            </>
          )}
        </Routes>
      </Router>
    </>
  );
}

export default App;
