import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin};
  width: 40px;
  height: 40px;
  background-color: #f3f8ff;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  transition: hover 100ms ease-in-out;

  &:hover {
    background-color: #e0e9f5;
  }

  &.toggleBtn {
    ${(props) =>
      props.status
        ? css`
            transform: rotate(0deg);
          `
        : css`
            transform: rotate(180deg);
          `}
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  color: var(--color-GreyGreen);
  background-image: url(${(props) => props.svgUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

function Buttons({ svgUrl, margin, onClick, className, disabled, status }) {
  return (
    <Container
      margin={margin}
      onClick={onClick}
      className={className}
      disabled={disabled}
      status={status}
    >
      <Icon svgUrl={svgUrl} />
    </Container>
  );
}

export default Buttons;
