import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";

import InfoContents from "../../molecules/ProjectInfoItem";
import Button from "../../molecules/ProjectButton";

import ModifyIcon from "../../../assets/svg/icon_modify.svg";
import DeleteIcon from "../../../assets/svg/icon_delete.svg";
import ToggleIcon from "../../../assets/svg/icon_toggle.svg";
import {
  useProjectDelete,
  useProjectDetail,
} from "../../../hooks/queries/useProjects";
import DeleteModal from "../../templates/DeleteModal";
import AlertSign from "../AlertSign";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 40px;
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  box-sizing: border-box;
  transition: all 50ms ease-in-out;
  ${(props) =>
    props.toggle
      ? css`
          height: 100px;
        `
      : css`
          height: 200px;
        `}
`;

const ProjectInfoWrap = styled.div`
  display: flex;
  align-items: flex-start;
  width: calc(100% - 280px);
  height: 100%;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  min-width: 50%;
  max-width: 50%;
`;

const ContentTitle = styled.h5`
  min-width: 120px;
  font-size: 15px;
  font-weight: 400;
  color: var(--color-GreyGreen);
`;

const ContentTitle2 = styled.h5`
  min-width: 120px;
  font-size: 15px;
  font-weight: 400;
  color: var(--color-GreyGreen);
  ${(props) =>
    props.toggle
      ? css`
          opacity: 0;
        `
      : css`
          opacity: 1;
        `}
`;

const ProjectTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  font-family: "Gmarket Sans";
  color: var(--color-BoldGreen);
`;

const ProjectDescription = styled.p`
  opacity: 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--color-BoldGreen);
  line-height: 1.4;
  ${(props) =>
    props.toggle
      ? css`
          opacity: 0;
        `
      : css`
          opacity: 1;
        `}
`;

const RightSection = styled.div`
  position: relative;
  top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 50%;
  height: fit-content;
  transition: all 100ms ease-in-out;
  ${(props) =>
    props.toggle
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 40px;
  max-width: 300px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 8px 0 8px 0;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 280px;
  > .toggleBtn {
    ${(props) =>
      props.status ? "transform: rotate(0deg)" : "transform: rotate(180deg)"}
  }
`;

function ProjectHeader() {
  // initial
  const navigate = useNavigate();

  const [status, setStatus] = useState(true);
  const [deleteModal, setDeleteModal] = useState({ visible: false, obj: {} });
  const [alertDisplay, setAlertDisplay] = useState(false);
  // 프로젝트 정보 표출
  const { projectId } = useParams();
  const { data } = useProjectDetail(projectId);
  const project = data;
  const { setAlertSign } = useAlertSign();
  // 프로젝트 삭제 function
  const { mutate } = useProjectDelete();

  const deleteConfirm = (e) => {
    e.stopPropagation();
    setDeleteModal({ visible: true, obj: project?.data });
  };

  const deleteHandler = useCallback(
    (obj) => {
      setDeleteModal({ visible: false, obj: {} });

      mutate(obj.id, {
        onSuccess: (data) => {
          navigate("/Project");
          setAlertSign("프로젝트 삭제가 완료되었습니다.");
        },
      });
    },
    [mutate]
  );

  return (
    <Container toggle={status}>
      <ProjectInfoWrap>
        <LeftSection>
          <Row>
            <ContentTitle>프로젝트 이름</ContentTitle>
            <ProjectTitle>
              {project?.data.name
                ? project.data.name
                : "프로젝트를 선택하세요."}
            </ProjectTitle>
          </Row>
          <Row>
            <ContentTitle2 toggle={status}>프로젝트 설명</ContentTitle2>
            <ProjectDescription toggle={status}>
              {project?.data.desc}
            </ProjectDescription>
          </Row>
        </LeftSection>
        <RightSection toggle={status}>
          <Column>
            <InfoContents
              contentName="네임스페이스"
              contentText={project?.data.ns}
              copyDP="flex"
            />
            <Row>
              <InfoContents
                contentName="타임존"
                contentText={project?.data.tz}
                margin="24px 0px 0px 0px"
              />
              <InfoContents
                contentName="최대 엔드포인트"
                contentText={project?.data.max_endpoints}
                margin="24px 0px 0px 40px"
              />
            </Row>
          </Column>
          <Column>
            {project?.data.username && (
              <InfoContents
                contentName="관리자"
                contentText={project?.data.username}
              />
            )}
            <InfoContents
              contentName="생성일"
              contentText={project?.data.created_at}
              margin={project.data.username ? "24px 0px 0px 0px" : ""}
            />
          </Column>
        </RightSection>
      </ProjectInfoWrap>
      <ButtonWrap status={!status}>
        <Link to={`/ModifyProject/${project?.data.id}`}>
          <Button svgUrl={ModifyIcon} />
        </Link>
        <Button
          svgUrl={DeleteIcon}
          margin="0 16px 0 16px"
          onClick={deleteConfirm}
        />
        <Button
          className="toggleBtn"
          svgUrl={ToggleIcon}
          onClick={(e) => setStatus(!status)}
          status={status}
        />
      </ButtonWrap>

      <DeleteModal
        display={deleteModal.visible}
        close={() => setDeleteModal(false)}
        modalTitle={"프로젝트 삭제"}
        modalTxt={"프로젝트를 삭제하시겠습니까?"}
        boxContents={
          deleteModal
            ? [
                { key: "프로젝트 이름", value: deleteModal.obj.name },
                { key: "프로젝트 설명", value: deleteModal.obj.desc },
              ]
            : []
        }
        announces={["삭제 이후 복구가 불가능합니다."]}
        confirmButtonClick={() => deleteHandler(deleteModal.obj)}
      />
    </Container>
  );
}

export default ProjectHeader;
