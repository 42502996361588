import React from "react";
import styled from "styled-components";

import TableHead from "../../molecules/TableHead";
import TableBody from "../../molecules/TableBody";
import InfoItem from "../../molecules/DetailInfoItem";
import SquareButton from "../../atoms/SquareButton";
import Pagination from "../../molecules/Pagination";

import PlusIcon from "../../../assets/svg/icon_plus.svg";
import MinusIcon from "../../../assets/svg/icon_minus.svg";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 40px;
`;

const LeftSide = styled.div`
  display: flex;
`;

const RightSide = styled.div`
  display: flex;

  & > div:nth-child(2) {
    margin-left: 12px;
  }
`;

const TableSection = styled.div`
  margin: 12px 0 40px 0;
  width: 100%;
  flex-grow: 1;
`;

const Table = styled.table`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

function ConnectTable({
  ths,
  tds,
  count,
  countText,
  addClickFn,
  removeClickFn,
  checkFn,
  allCheckHandler,
  checkedList,
  tableKey,
}) {
  return (
    <Container>
      <TableHeader>
        <LeftSide>
          <InfoItem contentName={countText} contentText={count} />
        </LeftSide>
        {addClickFn && (
          <RightSide>
            <SquareButton
              bgColor="var(--color-NormalGreen)"
              hvColor="var(--color-EmphasisGreen)"
              imgUrl={PlusIcon}
              onClick={addClickFn}
            />
            <SquareButton
              bgColor="#e5704c"
              hvColor="#c1230e"
              imgUrl={MinusIcon}
              onClick={removeClickFn}
            />
          </RightSide>
        )}
      </TableHeader>
      <TableSection>
        <Table>
          <TableHead
            ths={ths}
            allCheckHandler={allCheckHandler}
            tableKey={tableKey}
            value={checkedList?.length && checkedList?.length === tds?.length}
          />
          <TableBody
            tds={tds}
            ths={ths}
            tableHeight="530px"
            tableKey={tableKey}
            checkFn={checkFn}
            checkedList={checkedList}
          />
        </Table>
      </TableSection>
    </Container>
  );
}

export default ConnectTable;
