import React from "react";
import styled from "styled-components";

const InputTitle = styled.p`
  margin-right: 8px;
  font-size: 15px;
  font-weight: 600;
  color: var(--color-RegularGreen);
`;

function Input({ inputTitle }) {
  return <InputTitle>{inputTitle}</InputTitle>;
}

export default Input;
