import React from "react";
import styled, { css } from "styled-components";

import IconSearch from "../../../assets/svg/icon_search.svg";

const SearchButtonWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: var(--color-NormalGreen);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  cursor: pointer;
`;

const SearchIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${(props) => props.iconUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

function SearchButton({ onClick }) {
  return (
    <SearchButtonWrap onClick={onClick}>
      <SearchIcon iconUrl={IconSearch}></SearchIcon>
    </SearchButtonWrap>
  );
}

export default SearchButton;
