import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import LogoImage from "../../assets/svg/logo_gateway.svg";
import LoginIcon from "../../assets/svg/icon_login.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  width: 100%;
  min-width: 1600px;
  height: 100px;
  box-sizing: border-box;
  z-index: 100;
`;

const Logo = styled.div`
  width: 140px;
  height: 20px;
  background-image: url(${(props) => props.logoUrl});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const LoginButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: var(--color-NormalGreen);
  border-radius: 16px;
  transition: all 100ms;
  cursor: pointer;

  &:hover {
    background-color: var(--color-EmphasisGreen);
  }
`;

const LoginImage = styled.div`
  width: 25px;
  height: 25px;
  background-image: url(${(props) => props.iconUrl});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

function Header() {
  return (
    <Container>
      <Logo logoUrl={LogoImage} />
      <Link to="/Login">
        <LoginButton>
          <LoginImage iconUrl={LoginIcon} />
        </LoginButton>
      </Link>
    </Container>
  );
}

export default Header;
