import axios from "axios";
import { apiUrl } from "hooks/urls.js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAlert from "../useAlert";
import { useNavigate } from "react-router-dom";
import useAlertSign from "../useAlertSign";

const getUsagePlanList = (params) => {
  return axios.get(`${apiUrl("project")}/${params.projectId}/usageplans`, {
    params: params,
  });
};

export const useUsagePlanList = (params) => {
  return useQuery("usageplans", (e) => getUsagePlanList(params), {
    refetchOnWindowFocus: false,
  });
};

const getUsagePlanDetail = (params) => {
  return axios.get(
    `${apiUrl("project")}/${params.projectId}/usageplans/${params.id}`
  );
};

export const useUsagePlanDetail = (params) => {
  return useQuery("usageplan", (e) => getUsagePlanDetail(params), {
    refetchOnWindowFocus: false,
  });
};

const createUsagePlan = (data) => {
  return axios.post(`${apiUrl("project")}/${data.projectId}/usageplans`, data);
};

export const useUsagePlanCreate = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();

  return useMutation(createUsagePlan, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("usageplans");
      // navigate('/Project');
      setAlertSign("성공적으로 사용량 계획을 등록하였습니다");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

const updateUsagePlan = (data) => {
  return axios.put(
    `${apiUrl("project")}/${data.projectId}/usageplans/${data.id}`,
    data
  );
};

export const useUsagePlanUpdate = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useAlert();

  return useMutation(updateUsagePlan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("usageplans");
      queryClient.invalidateQueries("usageplan");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

const deleteUsagePlan = (params) => {
  return axios.delete(
    `${apiUrl("project")}/${params.projectId}/usageplans/${params.id}`
  );
};

export const useUsagePlanDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteUsagePlan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("usageplans");
    },
  });
};
