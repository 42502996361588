import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import MetaTag from "MetaTag";
import PageLayout from "components/templates/PageLayout";
import ProjectHeader from "components/organisms/ProjectHeader";
import AddModifyLayout from "components/templates/AddModifyLayout";
import SelectwithTitle from "components/molecules/SelectWithTitle";
import InputwithTitle from "components/molecules/InputWithTitle";
import TextareawithTitle from "components/molecules/TextareaWithTitle";
import InputTitle from "components/atoms/InputTitle";
import Tooltip from "components/atoms/Tooltip";
import Input from "components/atoms/Input";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEndpointDetail,
  useEndpointUpdate,
} from "hooks/queries/useEndpoints";
import useAlert from "hooks/useAlert";
import { endpointValidation } from "utils/validation";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;

const EssentialSign = styled.p`
  margin-right: 8px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-EmphasisGreen);
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-itmes: center;
  margin-bottom: 24px;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
  width: 100%;
`;

const InputTitleWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
`;

const URLDetail = styled.p`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-LightGreen);
`;

function ModifyEndpoint() {
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();
  const { projectId, id } = useParams();

  const [endpoint, setEndpoint] = useState({
    id: 0,
    name: "",
    desc: "",
    url_src_path: "",
    method: "ANY",
    url_tgt_full: "",
    projectId: projectId,
  });

  const [validate, setValidate] = useState(false);
  const { data } = useEndpointDetail({ id: id, projectId: projectId });

  useEffect(() => {
    if (data) {
      setEndpoint(Object.assign(data.data, { projectId: projectId }));
    }
  }, [data]);

  const { mutate } = useEndpointUpdate();
  const updateHandler = useCallback(() => {
    mutate(endpoint, {
      onSuccess: (data) => {
        navigate(`/Project/${projectId}/Endpoint/${id}`);
        setAlertSign("엔드포인트 수정이 완료되었습니다.");
      },
    });
  }, [endpoint]);

  const inputHandler = (e) => {
    let name = e.target.name;
    const currentStateCopy = endpoint;
    currentStateCopy[name] = e.target.value;
    setEndpoint(currentStateCopy);

    setValidate(endpointValidation(endpoint));
  };
  return (
    <>
      <MetaTag title="엔드포인트 수정" />
      <PageLayout>
        <ProjectHeader />
        <AddModifyLayout
          pageTitle="엔드포인트 수정"
          buttonName="수정"
          buttonClick={updateHandler}
          buttonStatus={!validate}
          breadCrumbs={[
            {
              pageName: "엔드포인트 관리",
              url: { url: `/Project/${projectId}`, state: "tab-endpoint" },
            },
            { pageName: "엔드포인트 수정", url: "" },
          ]}
        >
          <Container>
            <Row>
              <InputwithTitle
                width="100%"
                inputTitle="엔드포인트 이름"
                placeholder="엔드포인트 이름을 입력해주세요"
                tooltipTxt="엔드포인트 명칭"
                value={endpoint.name}
                name={"name"}
                onChange={inputHandler}
                maxLength={50}
              />
            </Row>
            <Row>
              <TextareawithTitle
                width="100%"
                areaHeight="120px"
                EsnSignDp="none"
                inputTitle="엔드포인트 설명"
                placeholder="엔드포인트 설명을 입력해주세요"
                tooltipTxt="엔드포인트 상세 내용"
                value={endpoint.desc}
                name={"desc"}
                onChange={inputHandler}
                maxLength={500}
              />
            </Row>
            <Column>
              <InputTitleWrap>
                <EssentialSign>*</EssentialSign>
                <InputTitle inputTitle="요청 URL" />
                <Tooltip tooltipTxt="<endpoint> 값을 입력해주세요" />
              </InputTitleWrap>
              <URLDetail>
                {process.env.REACT_APP_PROXY_BASE_URL}
                &#47;&lt;namespace&gt;&#47;&lt;endpoint&gt;와 같이 요청할 수
                있습니다.
              </URLDetail>
              <Input
                placeholder="<endpoint> 값을 입력해주세요"
                value={endpoint.url_src_path}
                name={"url_src_path"}
                onChange={inputHandler}
                maxLength={2048}
              />
            </Column>
            <Row>
              <SelectwithTitle
                width="100%"
                selectTitle="Method 방식"
                tooltipTxt="HTTP 호출 메소드"
                name={"method"}
                value={endpoint.method}
                onChange={inputHandler}
                items={[
                  "ANY",
                  "OPTION",
                  "GET",
                  "POST",
                  "PATCH",
                  "DELETE",
                  "PUT",
                ]}
              />
            </Row>
            <Row>
              <InputwithTitle
                width="100%"
                inputTitle="타겟 URL"
                placeholder="URL을 입력해주세요"
                tooltipTxt="게이트웨이가 호출할 실제 서비스 URL"
                value={endpoint.url_tgt_full}
                name={"url_tgt_full"}
                onChange={inputHandler}
                maxLength={2048}
              />
            </Row>
          </Container>
        </AddModifyLayout>
      </PageLayout>
    </>
  );
}

export default ModifyEndpoint;
