import React from "react";
import styled from "styled-components";

import CheckBox from "../../atoms/CheckBox";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Text = styled.p`
  margin-left: 12px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-GreyGreen);
`;

function InputwithTitle(props) {
  const { checkTxt, value, name, onChange } = props;
  return (
    <Container>
      <CheckBox {...props} />
      <Text>{checkTxt}</Text>
    </Container>
  );
}

export default InputwithTitle;
