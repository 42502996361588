import axios from "axios";
import { apiUrl } from "hooks/urls.js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAlert from "../useAlert";
import useAlertSign from "../useAlertSign";

const getApiKeyList = (params) => {
  return axios.get(`${apiUrl("project")}/${params.projectId}/apikeys`, {
    params: params,
  });
};

export const useApiKeyList = (params) => {
  return useQuery("apikeys", (e) => getApiKeyList(params), {
    refetchOnWindowFocus: false,
  });
};

const getApiKeyDetail = (params) => {
  return axios.get(
    `${apiUrl("project")}/${params.projectId}/apikeys/${params.id}`
  );
};

export const useApiKeyDetail = (params) => {
  return useQuery("apikey", (e) => getApiKeyDetail(params), {
    refetchOnWindowFocus: false,
  });
};

const createApiKey = (data) => {
  return axios.post(`${apiUrl("project")}/${data.projectId}/apikeys`, data);
};

export const useApiKeyCreate = () => {
  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();
  const queryClient = useQueryClient();

  return useMutation(createApiKey, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("apikeys");
      // navigate('/Project');
      setAlertSign("성공적으로 API Key를 등록하였습니다");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

const updateApiKey = (data) => {
  return axios.put(
    `${apiUrl("project")}/${data.projectId}/apikeys/${data.id}`,
    data
  );
};

export const useApiKeyUpdate = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useAlert();

  return useMutation(updateApiKey, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("apikeys");
      queryClient.invalidateQueries("apikey");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

const deleteApiKey = (params) => {
  return axios.delete(
    `${apiUrl("project")}/${params.projectId}/apikeys/${params.id}`
  );
};

export const useApiKeyDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteApiKey, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("apikeys");
    },
  });
};

const getEndPointsByPlans = (query) => {
  return axios.get(apiUrl(`endpointsByPlans`), { params: query });
};

export const useEndPointsByPlans = (params) => {
  return useQuery("endpointsByPlans", (e) => getEndPointsByPlans(params), {
    refetchOnWindowFocus: false,
  });
};

const getUsages = (params) => {
  return axios.get(
    `${apiUrl("project")}/${params.projectId}/apikeys/${params.id}/usages`,
    { params: params }
  );
};

export const useUsageList = (params) => {
  return useQuery("usages", (e) => getUsages(params), {
    refetchOnWindowFocus: false,
  });
};
