import axios from "axios";
import { apiUrl } from "hooks/urls.js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAlert from "../useAlert";

const getUsagePlanEndPoints = (params) => {
  return axios.get(
    `${apiUrl("project")}/${params.projectId}/usageplans/${params.id}/endpoints`
  );
};

const createUsagePlanEndPoint = (data) => {
  return axios.post(
    `${apiUrl("project")}/${data.projectId}/usageplans/${
      data.apiusageplan
    }/endpoints`,
    data
  );
};

const deleteUsagePlanEndPoint = (params) => {
  return axios.delete(
    `${apiUrl("project")}/${params.projectId}/usageplans/${
      params.apiusageplan
    }/endpoints/${params.apiendpoint}`
  );
};

export const useUsagePlanEndPoints = (params) => {
  return useQuery("UsagePlanEndPoints", (e) => getUsagePlanEndPoints(params), {
    refetchOnWindowFocus: false,
  });
};

export const useUsagePlanEndPointCreate = () => {
  const { setAlert } = useAlert();
  const queryClient = useQueryClient();

  return useMutation(createUsagePlanEndPoint, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("UsagePlanEndPoints");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

export const useUsagePlanEndPointDelete = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useAlert();

  return useMutation(deleteUsagePlanEndPoint, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("UsagePlanEndPoints");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};
