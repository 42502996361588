import React from "react";
import styled from "styled-components";

import Button from "../../atoms/Button";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 40px 40px 40px;
  width: calc(100% - 80px);
  height: auto;

  & > .confirm {
    display: ${(props) => (props.buttonDisplay ? "block" : "none")};
    width: ${(props) => props.confirmButtonWidth};
    margin-right: 20px;
    text-align: center;
  }
`;

function ModalFooter(props) {
  const {
    buttonDisplay,
    confirmButtonName,
    confirmButtonWidth,
    confirmButtonClick,
    defaultButtonWidth,
    defaultButtonName,
    close,
  } = props;

  return (
    <Container
      confirmButtonWidth={confirmButtonWidth}
      buttonDisplay={buttonDisplay}
    >
      <Button
        width="100%"
        bgColor="var(--color-NormalGreen)"
        hvColor="var(--color-EmphasisGreen)"
        txtColor="#FFFFFF"
        className={"confirm"}
        buttonName={confirmButtonName}
        onClick={confirmButtonClick}
      />
      <Button
        display="flex"
        width={defaultButtonWidth}
        bgColor="var(--color-BgBlue)"
        hvColor="#D8E8EF"
        txtColor="var(--color-RegularGreen)"
        buttonName={defaultButtonName}
        onClick={close}
      />
    </Container>
  );
}

export default ModalFooter;
