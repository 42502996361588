const base_url = "http://localhost:8080";

const urls = {
  login: "/v1/users/login",
  logout: "/v1/users/logout",
  user: "/v1/users",
  project: "/v1/projects",
  apikeysByPlans: "/v1/usageplans/apikeys",
  endpointsByPlans: "/v1/usageplans/endpoints",
};

export function apiUrl(url) {
  return process.env.REACT_APP_API_BASE_URL + urls[url];
}
