import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Table from "components/organisms/ConnectTable";
import { useEndPointUsagePlans } from "hooks/queries/useEndPointUsagePlan";
import { useApiKeysByPlans } from "../../../../hooks/queries/useUsagePlanApiKeys";
import {
  useApiKeyUsagePlanDelete,
  useApiKeyUsagePlans,
} from "../../../../hooks/queries/useApiKeyUsagePlans";
import { useEndPointsByPlans } from "../../../../hooks/queries/useApiKeys";

function ApiKeyEndPointList() {
  const { projectId, id } = useParams();
  const endpointThs = [
    { key: "index", label: "번호", flex: 1 },
    { key: "name", label: "엔드포인트 이름", flex: 6 },
    { key: "desc", label: "엔드포인트 설명", flex: 6 },
  ];

  // 매핑 관계
  const mappedData = useApiKeyUsagePlans({ id: id, projectId: projectId });
  const apiKeyUsagePlans = mappedData.data?.data || [];

  // API 키
  let UsagePlanIdList = apiKeyUsagePlans.map((row) => row.id);
  const endpointsQuery = useEndPointsByPlans({
    ids: UsagePlanIdList.toString(),
  });
  const endpoints = endpointsQuery?.data;

  return (
    <>
      <Table
        ths={endpointThs}
        tds={endpoints?.data}
        count={endpoints?.data.length || 0}
        countText={"연결된 엔드포인트"}
        tableKey={"mapped-apikey-endpoint"}
      />
    </>
  );
}

export default ApiKeyEndPointList;
