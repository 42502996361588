import React, { useId } from "react";
import styled from "styled-components";

import Th from "../../atoms/Th";
import CheckBox from "../../atoms/CheckBox";

const Container = styled.thead`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 48px;
  background-color: #dbede7;
  border-radius: 12px;
`;
const CheckBoxContains = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  text-align: center;
`;
const Tr = styled.tr`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

function TableHead({ ths, allCheckHandler, tds, value, tableKey }) {
  const id = useId();

  return (
    <Container>
      <Tr>
        {(ths || []).map((th, idx) =>
          th.key === "checkbox" ? (
            <CheckBoxContains>
              <CheckBox
                key={tableKey + "total"}
                onChange={allCheckHandler}
                tds={tds}
                value={value}
              />
            </CheckBoxContains>
          ) : (
            <Th key={tableKey + th?.key} thText={th?.label} thFlex={th?.flex} />
          )
        )}
      </Tr>
    </Container>
  );
}

export default TableHead;
