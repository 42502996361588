import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "components/organisms/ConnectTable";
import Modal from "components/templates/addMappedModal";
import DeleteModal from "../../../../components/templates/DeleteModal";
import useAlert from "../../../../hooks/useAlert";
import {
  useApiKeyUsagePlanCreate,
  useApiKeyUsagePlanDelete,
  useApiKeyUsagePlans,
} from "../../../../hooks/queries/useApiKeyUsagePlans";
import { useUsagePlanList } from "../../../../hooks/queries/useUsagePlans";
import useAlertSign from "../../../../hooks/useAlertSign";

function ApiKeyUsagePlanList() {
  const { projectId, id } = useParams();

  // 삭제 컨펌 모달
  const [deleteModal, setDeleteModal] = useState(false);

  // 키 연결 추가 모달 visible
  const [mapModal, setMapModal] = useState(false);
  const [addUsagePlanList, setAddUsagePlanList] = useState([]);
  const [removeUsagePlanList, setRemoveUsagePlanList] = useState([]);

  // tab
  const [searchValue, setSearchValue] = useState("");
  const [unUsedUsagePlans, setUnUsedUsagePlans] = useState([]);

  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();

  const usagePlanThs = [
    { key: "checkbox", label: "checkbox" },
    { key: "index", label: "번호", flex: 1 },
    { key: "name", label: "사용량 계획 이름", flex: 5 },
    { key: "desc", label: "사용량 계획 설명", flex: 5 },
  ];

  // 매핑 관계
  const mappedData = useApiKeyUsagePlans({ id: id, projectId: projectId });
  const ApiKeyUsagePlans = mappedData.data?.data || [];

  // 전체 사용량 계획
  const usagePlan = useUsagePlanList({
    id: id,
    projectId: projectId,
    no_page: true,
  });
  const usagePlanList = usagePlan.data?.data || [];

  useEffect(() => {
    if (usagePlanList && !usagePlanList.results) {
      let arr =
        usagePlanList.filter(
          (row) =>
            ApiKeyUsagePlans.findIndex(
              (usagePlan) => usagePlan.id === row.id
            ) === -1
        ) || [];
      if (unUsedUsagePlans.length !== arr.length) {
        let plans = new Set(arr);
        setUnUsedUsagePlans([...plans]);
      }
    }
  }, [ApiKeyUsagePlans, usagePlanList]);

  // 사용량 계획 연결 추가
  const mapCreate = useApiKeyUsagePlanCreate();

  const addHandler = (e) => {
    if (addUsagePlanList.length < 1) {
      setAlert(
        "fail",
        false,
        "사용량 계획 연결추가 오류",
        "선택된 사용량 계획이 없습니다. 사용량 계획을 선택해주세요."
      );
    } else {
      addUsagePlanList.map((row) => {
        mapCreate.mutate(
          { apikey: id, apiusageplan: row, projectId: projectId },
          {
            onSuccess: ({ data }) => {
              setMapModal(false);
              setAlertSign("사용량 계획 추가를 완료하였습니다.");
              setAddUsagePlanList([]);
            },
          }
        );
      });
    }
  };

  // 사용량 계획 연결 제거
  const removeModalHandler = () => {
    if (removeUsagePlanList.length < 1) {
      setAlert(
        "fail",
        false,
        "사용량 계획 연결제거 오류",
        "선택된 사용량 계획이 없습니다. 사용량 계획을 선택해주세요."
      );
    } else {
      setDeleteModal(!deleteModal);
    }
  };

  const mapDelete = useApiKeyUsagePlanDelete();
  const removeQuery = () => {
    removeUsagePlanList.map((row) => {
      mapDelete.mutate(
        { apikey: id, apiusageplan: row, projectId: projectId },
        {
          onSuccess: ({ data }) => {
            setDeleteModal(false);
            setAlertSign("사용량 계획 연결제거를 완료하였습니다.");
            setRemoveUsagePlanList((removeUsagePlanList) =>
              removeUsagePlanList.splice(0)
            );
          },
        }
      );
    });
  };

  const removeAllCheckHandler = (checked) => {
    let planIds = [];
    if (checked) {
      planIds = removeUsagePlanList;
      ApiKeyUsagePlans.map((row) => {
        planIds.push(row.id);
      });
      let plans = new Set(planIds);
      setRemoveUsagePlanList([...plans]);
    } else {
      setRemoveUsagePlanList(removeUsagePlanList.filter((arr) => {}));
    }
  };

  const addAllCheckHandler = (checked) => {
    let planIds = addUsagePlanList;
    if (checked) {
      unUsedUsagePlans.map((row) => {
        planIds.push(row.id);
      });
      let plans = new Set(planIds);
      setAddUsagePlanList([...plans]);
    } else {
      setAddUsagePlanList(addUsagePlanList.filter((arr) => {}));
    }
  };

  const addCheckBoxHandler = (e) => {
    let plans = [];
    const targetId = Number(e.target.name);
    if (e.target.checked) {
      plans = [...addUsagePlanList, targetId];
    } else {
      plans = addUsagePlanList.filter((plan) => plan !== targetId);
    }
    plans = new Set(plans);
    setAddUsagePlanList([...plans]);
  };

  const removeCheckBoxHandler = (e) => {
    let plans = [];
    const targetId = Number(e.target.name);
    if (e.target.checked) {
      plans = [...removeUsagePlanList, targetId];
    } else {
      plans = removeUsagePlanList.filter((plan) => plan !== targetId);
    }
    plans = new Set(plans);
    setRemoveUsagePlanList([...plans]);
  };

  const searchClick = () => {
    let unUsedList =
      usagePlanList.filter(
        (row) =>
          ApiKeyUsagePlans.findIndex((usagePlan) => usagePlan.id === row.id) ===
          -1
      ) || [];

    unUsedList = unUsedList.filter(
      (row) =>
        String(row.id).includes(searchValue) ||
        String(row.name).includes(searchValue) ||
        String(row.desc).includes(searchValue)
    );
    unUsedList = new Set(unUsedList);
    setUnUsedUsagePlans([...unUsedList]);
    setAddUsagePlanList([]);
  };

  return (
    <>
      <Table
        ths={usagePlanThs}
        tds={ApiKeyUsagePlans}
        count={ApiKeyUsagePlans?.length || 0}
        countText={"연결된 사용량 계획"}
        addClickFn={() => setMapModal(true)}
        removeClickFn={removeModalHandler}
        checkFn={removeCheckBoxHandler}
        allCheckHandler={(e) => removeAllCheckHandler(e.target.checked)}
        checkedList={removeUsagePlanList || []}
        tableKey={"mapped-apikey-usageplan"}
      />

      <Modal
        modalDP={mapModal}
        modalTitle="사용량 계획 연결 추가"
        modalTxt="추가할 사용량 계획을 선택해주세요"
        btnName="추가"
        infoItem1="기존 사용량 계획"
        infoItem2="선택한 사용량 계획"
        infoContent1={usagePlanList?.length || 0}
        close={() => setMapModal(false)}
        checkFn={addCheckBoxHandler}
        confirmButtonClick={(e) => addHandler()}
        ths={usagePlanThs}
        tds={unUsedUsagePlans}
        allCheckHandler={(e) => addAllCheckHandler(e.target.checked)}
        checkedList={addUsagePlanList || []}
        changeSearchValue={(e) => setSearchValue(e.target.value)}
        searchClick={(e) => searchClick()}
        tableKey={"add-apikey-usageplan"}
      />

      <DeleteModal
        display={deleteModal}
        close={() => setDeleteModal(!deleteModal)}
        modalTitle={"사용량 계획 연결제거"}
        modalTxt={"선택한 사용량 계획 연결을 제거하시겠습니까?"}
        boxContents={
          deleteModal
            ? [{ key: "선택한 사용량 계획", value: removeUsagePlanList.length }]
            : []
        }
        announces={[
          "연결관계만 제거되며, 실제 사용량 계획은 삭제되지 않습니다.",
        ]}
        confirmButtonClick={removeQuery}
      />
    </>
  );
}

export default ApiKeyUsagePlanList;
