import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import MetaTag from "MetaTag";
import ProjectHeader from "components/organisms/ProjectHeader";
import OptionButton from "components/molecules/ProjectButton";
import BackButton from "components/atoms/BackButton";
import PageLayout from "components/templates/PageLayout";
import PageTitle from "components/organisms/PageTitle";
import DetailBox from "components/organisms/DetailBox";
import DetailInfoItem from "components/molecules/DetailInfoItem";
import TabMenu from "components/molecules/TabMenu";

import TabIcon1 from "assets/svg/tab_icon_1.svg";
import TabIcon2 from "assets/svg/tab_icon_2.svg";
import TabIcon3 from "assets/svg/tab_icon_3.svg";
import ModifyIcon from "assets/svg/icon_modify.svg";
import DeleteIcon from "assets/svg/icon_delete.svg";
import {
  useApiKeyDelete,
  useApiKeyDetail,
} from "../../../hooks/queries/useApiKeys";
import DeleteModal from "../../../components/templates/DeleteModal";
import TabBox from "../../../components/organisms/TabBox";
import ApiKeyUsagePlanList from "./ApiKeyUsagePlanList";
import ApiKeyEndPointList from "./ApiKeyEndPointList";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
  padding: 0 40px 40px 40px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ContentsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  box-sizing: border-box;
  margin-top: 24px;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
`;

const ColumnSide = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.h5`
  margin-bottom: 4px;
  font-size: 15px;
  font-weught: 400;
  color: var(--color-GreyGreen);
`;

const APIKeyName = styled.h3`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Gmarket Sans";
`;

const BoxButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;

const APIKeyDescription = styled.p`
  width: 100%;
  max-width: 100%;
`;

const InfoWrap = styled.div`
  displat: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  width: 100%;
`;

function ApiKeyDetail() {
  const { projectId, id } = useParams();
  const { setAlertSign } = useAlertSign();
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState({ visible: false, obj: {} });
  const [selectedTab, setSelectedTab] = useState("usageplan");
  const { data } = useApiKeyDetail({ id: id, projectId: projectId });
  const apikey = data?.data;

  const deleteConfirm = (e, obj) => {
    e.stopPropagation();
    setDeleteModal({ visible: true, obj: obj });
  };

  const { mutate } = useApiKeyDelete();
  const deleteHandler = useCallback(
    (obj) => {
      const projectIncludeObj = Object.assign({}, obj, {
        projectId: projectId,
      });
      setDeleteModal({ visible: false, obj: {} });

      mutate(projectIncludeObj, {
        onSuccess: (data) => {
          navigate(`/Project/${projectId}`, { state: "tab-apikey" });
          setAlertSign("API 키가 삭제되었습니다.");
        },
      });
    },
    [mutate]
  );

  const [tabState, setTabState] = useState("tab-endpoint");

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setTabState(state);
    }
  }, []);

  // tab items
  const tabs = [
    {
      id: "tab-endpoint",
      iconUrl: TabIcon1,
      tabName: "엔드포인트 관리",
    },
    {
      id: "tab-usageplan",
      iconUrl: TabIcon2,
      tabName: "사용량계획 관리",
    },
    {
      id: "tab-apikey",
      iconUrl: TabIcon3,
      tabName: "API 키 관리",
    },
  ];

  const copyAction = async (text) => {
    const textArea = document.createElement("textarea");

    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();

    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  return (
    <>
      <MetaTag title="API키 상세정보" />
      <PageLayout>
        <ProjectHeader />

        <Container>
          <Header>
            <HeaderLeftSide>
              <BackButton
                link={(e) =>
                  navigate(`/Project/${projectId}`, { state: "tab-apikey" })
                }
              />
              <PageTitle
                pageTitle="API 키 상세정보"
                breadCrumbs={[
                  {
                    pageName: "API 키 관리",
                    url: { url: `/Project/${projectId}`, state: "tab-apikey" },
                  },
                  { pageName: "API 키 상세정보", url: "" },
                ]}
              />
            </HeaderLeftSide>
            <TabMenu
              tabs={tabs}
              tabSelectedFn={(e) =>
                navigate(`/Project/${projectId}`, { state: e.target.id })
              }
              selected={"tab-apikey"}
            />
          </Header>
          <ContentsWrap>
            {/* 왼쪽박스 start */}
            <DetailBox href="/ModifyAPIKey">
              <HeaderRow>
                <ColumnSide>
                  <ContentTitle>API 키 이름</ContentTitle>
                  <APIKeyName>{apikey?.name}</APIKeyName>
                </ColumnSide>
                <BoxButtonWrap>
                  <Link
                    to={`/Project/${projectId}/ModifyAPIKey/${id}`}
                    state={"tab-apikey"}
                  >
                    <OptionButton svgUrl={ModifyIcon} />
                  </Link>
                  <OptionButton
                    svgUrl={DeleteIcon}
                    margin="0 0 0 16px"
                    onClick={(e) => deleteConfirm(e, apikey)}
                  />
                </BoxButtonWrap>
              </HeaderRow>
              <ColumnSide>
                <ContentTitle>API 키 설명</ContentTitle>
                <APIKeyDescription>{apikey?.desc}</APIKeyDescription>
              </ColumnSide>
              <InfoWrap>
                <DetailInfoItem
                  contentName="API 키"
                  contentText={apikey?.value}
                  copyDP="flex"
                  onClick={(e) => copyAction(apikey?.value)}
                />
              </InfoWrap>
            </DetailBox>
            {/* 왼쪽박스 end */}

            {/* 오른쪽박스 start */}
            <TabBox
              tabs={[
                { name: "사용량 계획", tabId: "usageplan" },
                { name: "엔드포인트", tabId: "endpoint" },
              ]}
              selectedTab={selectedTab}
              onClick={(e) => setSelectedTab(e.target.id)}
            >
              {selectedTab === "usageplan" ? (
                <ApiKeyUsagePlanList />
              ) : (
                <ApiKeyEndPointList />
              )}
            </TabBox>
            {/* 오른쪽박스 end */}
          </ContentsWrap>
        </Container>

        <DeleteModal
          display={deleteModal.visible}
          close={() => setDeleteModal({ visible: false, obj: {} })}
          modalTitle={"API 키 삭제"}
          modalTxt={"API 키를 삭제하시겠습니까?"}
          boxContents={
            deleteModal
              ? [
                  { key: "API 키 이름", value: deleteModal.obj.name },
                  { key: "API 키 설명", value: deleteModal.obj.desc },
                  { key: "API 키", value: deleteModal.obj.value },
                  {
                    key: "활성화 상태",
                    value: deleteModal.obj.is_active ? "활성화" : "비활성화",
                  },
                ]
              : []
          }
          announces={[
            "삭제 이후 복구가 불가능합니다.",
            "삭제 시 연결된 항목들도 함께 제거됩니다.",
          ]}
          confirmButtonClick={() => deleteHandler(deleteModal.obj)}
        />
      </PageLayout>
    </>
  );
}

export default ApiKeyDetail;
