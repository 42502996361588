import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import SearchBar from "../../molecules/SearchBar";
import AddButton from "../../atoms/Button";
import TableHead from "../../molecules/TableHead";
import TableBody from "../../molecules/TableBody";
import Pagination from "../../molecules/Pagination";

import AddIcon from "../../../assets/svg/icon_add.svg";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
`;

const TableSection = styled.div`
  margin: 24px 0 40px 0;
  width: 100%;
  flex-grow: 1;
`;

const Table = styled.table`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

function TableLayout({
  buttonName,
  addButtonLink,
  ths,
  tds,
  detailLink,
  searchValue,
  changeSearchValue,
  searchClick,
  count,
  page,
  pageMoveHandler,
  deleteHandler,
  aggregateModalHandler,
  tableKey,
  tabState,
}) {
  return (
    <Container>
      <BoxHeader>
        <SearchBar
          placeholder="검색어를 입력해주세요"
          value={searchValue}
          onChange={changeSearchValue}
          searchClick={searchClick}
        />
        <Link to={addButtonLink} state={tabState}>
          <AddButton
            width="200px"
            bgColor="var(--color-NormalGreen)"
            hvColor="var(--color-EmphasisGreen)"
            txtColor="#FFFFFF"
            imgUrl={AddIcon}
            leftIcon="none"
            rightIcon="block"
            buttonName={buttonName}
          />
        </Link>
      </BoxHeader>
      <TableSection>
        <Table>
          <TableHead ths={ths} tableKey={tableKey} />
          <TableBody
            ths={ths}
            tds={tds}
            href={detailLink}
            count={count}
            page={page}
            tableKey={tableKey}
            tableHeight="530px"
            deleteHandler={deleteHandler}
            aggregateModalHandler={aggregateModalHandler}
          />
        </Table>
      </TableSection>
      <Pagination count={count} page={page} pageMoveHandler={pageMoveHandler} />
    </Container>
  );
}

export default TableLayout;
