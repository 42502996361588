import React from "react";
import styled from "styled-components";

import Navigation from "../../molecules/Navigation";

const Container = styled.div`
  position: relative;
  top: 2px;
`;

const TitleText = styled.h2`
  margin-top: 8px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Gmarket Sans";
  color: var(--color-BoldGreen);
`;

function PageTitle({ pageTitle, breadCrumbs }) {
  return (
    <Container>
      <Navigation breadCrumbs={breadCrumbs} />
      <TitleText>{pageTitle}</TitleText>
    </Container>
  );
}

export default PageTitle;
