import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Table from "components/organisms/ConnectTable";
import { useEndPointUsagePlans } from "hooks/queries/useEndPointUsagePlan";
import { useApiKeysByPlans } from "../../../../hooks/queries/useUsagePlanApiKeys";

function EndPointApiKeyList() {
  const { projectId, id } = useParams();
  const apikeyThs = [
    { key: "index", label: "번호", flex: 1 },
    { key: "ApiKey", label: "사용량계획 이름", flex: 4 },
    { key: "name", label: "API 키 이름", flex: 4 },
    { key: "desc", label: "API 키 설명", flex: 4 },
  ];

  // 매핑 관계
  const mappedData = useEndPointUsagePlans({ id: id, projectId: projectId });
  const endPointUsagePlans = mappedData.data?.data || [];

  // API 키
  let UsagePlanIdList = endPointUsagePlans.map((row) => row.id);
  const apikeysQuery = useApiKeysByPlans({ ids: UsagePlanIdList.toString() });
  const apikeys = apikeysQuery?.data;

  return (
    <>
      <Table
        ths={apikeyThs}
        tds={apikeys?.data}
        count={apikeys?.data.length || 0}
        countText={"연결된 API 키"}
        tableKey={"mapped-endpoint-apikey"}
      />
    </>
  );
}

export default EndPointApiKeyList;
