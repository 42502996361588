import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 32px;
  border-radius: 16px;
  background-color: ${(props) => props.bgColor};
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
`;

function UsageSign({ flag }) {
  return (
    <Container
      bgColor={flag ? "var(--color-EmphasisGreen)" : "var(--color-BaseGreen)"}
    >
      <Text>{flag ? "사용중" : "미사용"}</Text>
    </Container>
  );
}

export default UsageSign;
