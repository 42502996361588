import axios from "axios";
import { apiUrl } from "hooks/urls.js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAlert from "../useAlert";

const getApikeyUsagePlans = (params) => {
  return axios.get(
    `${apiUrl("project")}/${params.projectId}/apikeys/${params.id}/usageplans`
  );
};

const createApiKeyUsagePlan = (data) => {
  return axios.post(
    `${apiUrl("project")}/${data.projectId}/apikeys/${data.apikey}/usageplans`,
    data
  );
};

const deleteApiKeyUsagePlan = (params) => {
  return axios.delete(
    `${apiUrl("project")}/${params.projectId}/apikeys/${
      params.apikey
    }/usageplans/${params.apiusageplan}`
  );
};

export const useApiKeyUsagePlans = (params) => {
  return useQuery("ApiKeyUsagePlans", (e) => getApikeyUsagePlans(params), {
    refetchOnWindowFocus: false,
  });
};

export const useApiKeyUsagePlanCreate = () => {
  const { setAlert } = useAlert();
  const queryClient = useQueryClient();

  return useMutation(createApiKeyUsagePlan, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("ApiKeyUsagePlans");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};

export const useApiKeyUsagePlanDelete = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useAlert();

  return useMutation(deleteApiKeyUsagePlan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("ApiKeyUsagePlans");
    },
    onError: (data) => {
      setAlert(
        "fail",
        false,
        "실패",
        "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
      );
    },
  });
};
