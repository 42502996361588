import React, { useState, useRef } from "react";
import styled from "styled-components";

import LogoImage from "../../assets/svg/logo_footer.svg";
import SelectIcon from "../../assets/svg/icon_index_arrow.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  width: 100%;
  min-width: 1600px;
  background-color: var(--color-RegularGreen);
  box-sizing: border-box;
  z-index: 100;
`;

// ----------------------------------------------

const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

// const SubTitle = styled.h3`
//   margin-bottom: 24px;
//   font-size: 16px;
//   font-weight: 600;
//   color: var(--color-BaseGreen);
// `;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const RightSection = styled.div`
  width: fit-content;
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 80px;
  height: 100%;
`;

const Logo = styled.div`
  width: 144px;
  height: 20px;
  background-image: url(${(props) => props.logoUrl});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const TermsSection = styled.div`
  display: flex;
  align-items: center;
`;

const TermsItme = styled.a`
  display: block;
  padding: ${(props) => props.padding};
  font-size: 14px;
  font-weight: 600;
  color: var(--color-GreyGreen);
  cursor: pointer;

  &.center {
    border-left: solid 1px var(--color-GreyGreen);
    border-right: solid 1px var(--color-GreyGreen);
  }
`;

const FamilySiteWrap = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 160px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-RegularGreen);
  background-color: var(--color-GreyGreen);
  background-image: url(${(props) => props.arrowUrl});
  background-position: calc(100% - 24px) 50%;
  background-repeat: no-repeat;
  border-radius: 8px;
  cursor: pointer;
`;

const FamilyButton = styled.button`
  display: flex;
  align-items: center;
  width: inherit;
  height: inherit;
  border: 0 none;
  outline: 0 none;
  padding-left: 16px;
  background-color: transparent;
  cursor: pointer;
`;

const FamilySiteList = styled.ul`
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  color: var(--color-RegularGreen);
  background-color: var(--color-GreyGreen);
  list-style-type: none;
  padding: 0;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  max-height: 0;
  transition: 50ms ease-in;

  &.active {
    max-height: 45px;
  }

  &.hide {
    max-height: 0;
  }
`;

const ConnectBar = styled.div`
  width: 100%;
  height: 5px;
  background-color: var(--color-GreyGreen);
`;

const SiteItemWrap = styled.li`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 100%;
  height: 40px;
`;

const SiteItem = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  transition: 100ms;

  &:hover {
    background-color: #607870;
  }
`;

const SiteLink = styled.a`
  box-sizing: border-box;
  display: block;
  padding: 8px 12px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-RegularGreen);
  vertical-align: middle;
`;

//---------------------------------------------------------

const BottomLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 80px;
`;

const RowLine = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const TextItem = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #5f7c73;
  padding: ${(props) => props.infoPadding};

  &.center {
    border-left: solid 1px #5f7c73;
    border-right: solid 1px #5f7c73;
  }

  &.right {
    border-right: solid 1px #5f7c73;
  }
`;

const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Copyright = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #5f7c73;
`;

function Footer({}) {
  const selectBtn = useRef(null);
  const [isOpen, setMenu] = useState(false);
  const onSelectToggle = () => {
    setMenu((isOpen) => !isOpen);
  };
  const openFamilySite = (siteUrl) => {
    window.open(siteUrl, '_blank');
    onSelectToggle();
  }

  return (
    <Container>
      <TopLine>
        <LeftSection>
          <LogoSection>
            <Logo logoUrl={LogoImage} />
          </LogoSection>
          <TermsSection>
            <TermsItme
              padding="0 16px 0 0"
              target="_blank"
              href="https://www.datansoft.com"
            >
              회사소개
            </TermsItme>
            <TermsItme className="center" padding="0 16px">
              이용약관
            </TermsItme>
            <TermsItme padding="0 0 0 16px">개인정보 처리방침</TermsItme>
          </TermsSection>
        </LeftSection>
        <RightSection>
          <FamilySiteWrap arrowUrl={SelectIcon}>
            <FamilyButton
              onClick={() => {
                onSelectToggle();
              }}
            >
              Family Site
            </FamilyButton>
            <FamilySiteList
              className={isOpen ? "active" : "hide"}
              ref={selectBtn}
            >
              <ConnectBar />
              <SiteItemWrap>
                <SiteItem>
                  <SiteLink onClick={() => {openFamilySite("https://www.matgim.ai")}}>
                    맡김 AI
                  </SiteLink>
                </SiteItem>
              </SiteItemWrap>
            </FamilySiteList>
          </FamilySiteWrap>
        </RightSection>
      </TopLine>
      <BottomLine>
        <LeftSection>
          <InfoSection>
            <RowLine>
              <TextItem infoPadding="0 12px 0 0">
                상호 &#58; &#40;주&#41; 데이탄소프트
              </TextItem>
              <TextItem className="center" infoPadding="0 12px">
                대표이사 &#58; 명재석
              </TextItem>
              <TextItem infoPadding="0 0 0 12px">
                주소 &#58; 제주특별자치도 제주시 도령북길 31, 2층 데이탄소프트
              </TextItem>
            </RowLine>
            <RowLine>
              <TextItem className="right" infoPadding="0 12px 0 0">
                사업자번호 &#58; 326-88-01855
              </TextItem>
              <TextItem infoPadding="0 0 0 12px">
                통신판매업 신고번호 &#58; 제 2022-제주연동-0076 호
              </TextItem>
            </RowLine>
          </InfoSection>
          <ContactSection>
            <RowLine>
              <TextItem>전화번호 : 070-7773-9844</TextItem>
            </RowLine>
            <RowLine>
              <TextItem>이메일 주소 : contact@datansoft.com</TextItem>
            </RowLine>
          </ContactSection>
        </LeftSection>
        <Copyright>
          {" "}
          &#40;C&#41; DataNSoft Inc. All rights reserved.
        </Copyright>
      </BottomLine>
    </Container>
  );
}

export default Footer;
