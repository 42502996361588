import React, { createContext, useState } from "react";

const initialState = {
  type: "check",
  message: "완료되었습니다",
  title: "성공",
  singleButton: true,
};

const AlertContext = createContext({
  ...initialState,
  setAlert: () => {},
  removeAlert: () => {},
});

export function AlertProvider({ children }) {
  const [display, setDisplay] = useState(false);
  const [singleButton, setSingleButton] = useState(false);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const setAlert = (type, singleButton, title, message) => {
    setDisplay(true);
    setType(type);
    setSingleButton(singleButton);
    setTitle(title);
    setMessage(message);
  };

  const removeAlert = () => {
    setDisplay(false);
  };

  const contextValue = {
    type,
    singleButton,
    title,
    message,
    display,
    setAlert,
    removeAlert,
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
}

export default AlertContext;
