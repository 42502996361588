import React, { useEffect, useState } from "react";
import styled from "styled-components";

import MetaTag from "MetaTag";
import ProjectHeader from "components/organisms/ProjectHeader";
import TabMenu from "components/molecules/TabMenu";
import PageLayout from "components/templates/PageLayout";
import PageTitle from "components/organisms/PageTitle";
import BackButton from "components/atoms/BackButton";
import { useLocation, useParams } from "react-router-dom";
import TabIcon1 from "assets/svg/tab_icon_1.svg";
import TabIcon2 from "assets/svg/tab_icon_2.svg";
import TabIcon3 from "assets/svg/tab_icon_3.svg";
import EndpointList from "page/EndPoint/EndpointList";
import UsagePlanList from "../../UsagePlan/UsagePlanList";
import APIKeyList from "../../ApiKey/APIKeyList";
import { useNavigate } from "react-router-dom";
const Container = styled.div`
  box-sizing: border-box;
  margin-top: 24px;
  padding: 0 40px 40px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 24px;
  padding: 40px;
  width: 100%;
  flex-grow: 1;
  transition: height 0.1s;
  background-color: #ffffff;
  border-radius: 32px;
`;

function ProjectDetail() {
  const [tabState, setTabState] = useState("tab-endpoint");

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setTabState(state);
    }
  }, []);

  // 프로젝트 ID 값 저장
  const { projectId } = useParams();

  // tab items
  const tabs = [
    {
      id: "tab-endpoint",
      iconUrl: TabIcon1,
      tabName: "엔드포인트 관리",
      headerName: "엔드포인트 목록",
    },
    {
      id: "tab-usageplan",
      iconUrl: TabIcon2,
      tabName: "사용량계획 관리",
      headerName: "사용량계획 목록",
    },
    {
      id: "tab-apikey",
      iconUrl: TabIcon3,
      tabName: "API 키 관리",
      headerName: "API 키 목록",
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <MetaTag title={tabs.find((row) => row.id === tabState)?.headerName} />

      <PageLayout>
        <ProjectHeader />

        <Container>
          <Header>
            <HeaderLeftSide>
              <BackButton link={(e) => navigate(`/Project`)} />
              <PageTitle
                pageTitle={tabs.find((row) => row.id === tabState)?.headerName}
                breadCrumbs={[
                  {
                    pageName: tabs.find((row) => row.id === tabState)?.tabName,
                  },
                ]}
              />
            </HeaderLeftSide>
            <TabMenu
              tabs={tabs}
              tabSelectedFn={(e) => setTabState(e.target.id)}
              selected={tabState}
            />
          </Header>

          <Box>
            {tabState === "tab-endpoint" ? (
              <EndpointList projectId={projectId} />
            ) : tabState === "tab-usageplan" ? (
              <UsagePlanList projectId={projectId} />
            ) : (
              tabState === "tab-apikey" && <APIKeyList projectId={projectId} />
            )}
          </Box>
        </Container>
      </PageLayout>
    </>
  );
}

export default ProjectDetail;
