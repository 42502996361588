import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;
`;

const Label = styled.label``;

function CheckBox({ value, onChange, name, disabled }) {
  const [status, setStatus] = useState("");

  useEffect(() => {
    setStatus(value);
  }, [value]);

  const changeHandler = (event) => {
    setStatus(!status);
    onChange(event);
  };

  return (
    <Container>
      <Input
        type="checkbox"
        value={String(value)}
        name={name}
        checked={status}
        disabled={disabled}
        onChange={changeHandler}
      ></Input>
      <Label></Label>
    </Container>
  );
}

export default CheckBox;
