import React, { useState } from "react";
import styled from "styled-components";
import useAlertSign from "../../../hooks/useAlertSign";

import CopyIcon from "../../../assets/svg/icon_copy.svg";
import AlertSign from "../../organisms/AlertSign";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin: ${(props) => props.margin};
`;

const ContentTitle = styled.h5`
  font-size: 15px;
  font-weight: 400;
  color: var(--color-GreyGreen);
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.p`
  margin-top: 4px;
  font-size: 15px;
  font-weight: 400;
  color: var(--color-BoldGreen);
`;

const CopyButton = styled.div`
  display: ${(props) => (props.copyDP ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background-color: var(--color-BaseBlue);
  transition: all 100ms;
  cursor: pointer;

  &:hover {
    background-color: #e0e9f5;
  }
`;

const CopyImage = styled.div`
  width: 11px;
  height: 12px;
  background-image: url(${(props) => props.copyImg});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

function InfoContents({ contentName, contentText, margin, copyDP }) {
  const { setAlertSign } = useAlertSign();
  const copyAction = async (text) => {
    const textArea = document.createElement("textarea");

    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();

    document.execCommand("copy");
    document.body.removeChild(textArea);
    setAlertSign("네임스페이스가 복사되었습니다");
  };

  return (
    <Container margin={margin}>
      <ContentTitle>{contentName}</ContentTitle>
      <Row>
        <Content>{contentText}</Content>
        <CopyButton copyDP={copyDP} onClick={(e) => copyAction(contentText)}>
          <CopyImage copyImg={CopyIcon} />
        </CopyButton>
      </Row>
    </Container>
  );
}

export default InfoContents;
