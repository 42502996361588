import React from "react";
import styled from "styled-components";

import Layout from "../../organisms/ModalLayout";
import Table from "../../organisms/ModalTable";

const ModalText = styled.p`
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-BoldGreen);
`;

function addMappedModal({
  modalDP,
  modalTitle,
  modalTxt,
  infoItem1,
  infoItem2,
  infoContent1,
  ths,
  tds,
  close,
  checkFn,
  confirmButtonClick,
  allCheckHandler,
  checkedList,
  changeSearchValue,
  searchClick,
  tableKey,
}) {
  return (
    <Layout
      modalDP={modalDP}
      width="1000px"
      modalTitle={modalTitle}
      buttonDisplay={true}
      confirmButtonName="추가"
      defaultButtonName="취소"
      confirmButtonClick={confirmButtonClick}
      confirmButtonWidth="200px"
      defaultButtonWidth="200px"
      close={close}
    >
      <ModalText>{modalTxt}</ModalText>
      <Table
        ths={ths}
        tds={tds}
        allCheckHandler={allCheckHandler}
        infoName1={infoItem1}
        infoName2={infoItem2}
        infoContent1={infoContent1}
        infoContent2={checkedList.length}
        checkFn={checkFn}
        checkedList={checkedList}
        changeSearchValue={changeSearchValue}
        searchClick={searchClick}
        tableKey={tableKey}
      />
    </Layout>
  );
}

export default addMappedModal;
