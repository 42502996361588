import React from "react";
import styled from "styled-components";

import ArrowIcon from "../../../assets/svg/green_arrow.svg";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Arrow = styled.div`
  margin: 0 6px;
  width: 12px;
  height: 12px;
  background-image: url(${(props) => props.imgUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const PageName = styled.a`
  font-size: 14px;
  font-weight: 400;
  color: #719dba;
  cursor: pointer;
`;

function NaviItem({ pageName, pageUrl }) {
  const navigate = useNavigate();
  return (
    <Container>
      <Arrow imgUrl={ArrowIcon} />
      <PageName
        onClick={(e) => navigate(pageUrl?.url, { state: pageUrl?.state })}
      >
        {pageName}
      </PageName>
    </Container>
  );
}

export default NaviItem;
