import React from "react";
import styled from "styled-components";

import TabItem from "../../atoms/TabItem";

const Container = styled.div`
  display: ${(props) => (props.tabDP ? "none" : "flex")};
  justify-content: flex-start;
  align-items: center;
`;

const Space = styled.div`
  width: 12px;
`;

function TabMenu({ tabs, tabSelectedFn, selected, tabDP }) {
  return (
    <Container tabDP={tabDP}>
      {tabs.map((tab, idx) => (
        <React.Fragment key={tab.id}>
          <TabItem
            id={tab.id}
            iconUrl={tab.iconUrl}
            tabName={tab.tabName}
            onClick={tabSelectedFn}
            selected={selected === tab.id}
            href={tab.href}
          />
          {idx !== tabs.length - 1 && <Space />}
        </React.Fragment>
      ))}
    </Container>
  );
}

export default TabMenu;
