import React, { useCallback, useState } from "react";
import styled from "styled-components";

import MetaTag from "MetaTag";
import PageLayout from "components/templates/PageLayout";
import ProjectHeader from "components/organisms/ProjectHeader";
import AddModifyLayout from "components/templates/AddModifyLayout";
import InputwithTitle from "components/molecules/InputWithTitle";
import TextareawithTitle from "components/molecules/TextareaWithTitle";
import { useNavigate, useParams } from "react-router-dom";
import { apikeyValidation } from "../../../utils/validation";
import { useApiKeyCreate } from "../../../hooks/queries/useApiKeys";
import PageTitle from "../../../components/organisms/PageTitle";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-itmes: center;
  margin-bottom: 24px;
  width: 100%;
`;

function AddAPIKey() {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [apikey, setApikey] = useState({
    name: "",
    desc: "",
    projectId: projectId,
  });

  const [validate, setValidate] = useState(false);

  const { mutate } = useApiKeyCreate();

  const addHandler = useCallback(() => {
    mutate(apikey, {
      onSuccess: () => {
        navigate(`/Project/${projectId}`, { state: "tab-apikey" });
      },
    });
  }, [mutate]);

  const inputHandler = (e) => {
    let name = e.target.name;
    apikey[name] = e.target.value;

    setValidate(apikeyValidation(apikey));
  };

  return (
    <>
      <MetaTag title="API키 추가" />
      <PageLayout>
        <ProjectHeader />
        <AddModifyLayout
          pageTitle="API 키 생성"
          buttonName="생성"
          buttonClick={addHandler}
          buttonStatus={!validate}
          breadCrumbs={[
            {
              pageName: "API 키 관리",
              url: { url: `/Project/${projectId}`, state: "tab-apikey" },
            },
            { pageName: "API 키 생성", url: { url: "", state: "" } },
          ]}
          tabState={"tab-apikey"}
        >
          <Container>
            <Row>
              <InputwithTitle
                width="100%"
                inputTitle="API 키 이름"
                placeholder="API 키 이름을 입력해주세요"
                tooltipTxt="API 키의 명칭"
                value={apikey.name}
                name={"name"}
                onChange={inputHandler}
                maxLength={50}
              />
            </Row>
            <Row>
              <TextareawithTitle
                width="100%"
                areaHeight="120px"
                EsnSignDp="none"
                inputTitle="API 키 설명"
                placeholder="API 키 설명을 입력해주세요"
                tooltipTxt="API 키 상세 내용"
                value={apikey.desc}
                name={"desc"}
                onChange={inputHandler}
                maxLength={500}
              />
            </Row>
            <Row>
              <InputwithTitle
                width="100%"
                inputTitle="API 키"
                EsnSignDp="none"
                placeholder={"자동으로 생성됩니다"}
                tooltipTxt="API 키의 값"
                disabled={true}
              />
            </Row>
          </Container>
        </AddModifyLayout>
      </PageLayout>
    </>
  );
}

export default AddAPIKey;
