import React, { useCallback, useState } from "react";
import ListTable from "components/organisms/ListTable";
import {
  useApiKeyDelete,
  useApiKeyList,
} from "../../../hooks/queries/useApiKeys";
import DeleteModal from "../../../components/templates/DeleteModal";
import AggregateModal from "../../../components/templates/AggregateModal";
import useAlertSign from "../../../hooks/useAlertSign";

function APIKeyList({ projectId }) {
  const { setAlertSign } = useAlertSign();
  const [params, setParameter] = useState({
    search: "",
    page: 1,
    projectId: projectId,
  });
  const [deleteModal, setDeleteModal] = useState({ visible: false, obj: {} });
  const [aggregateModal, setAggregateModal] = useState({
    visible: false,
    obj: {},
  });

  const ths = [
    { key: "index", label: "번호", flex: 1 },
    { key: "name", label: "API 키 이름", flex: 3 },
    { key: "desc", label: "API 키 설명", flex: 4 },
    { key: "value", label: "API 키", flex: 4 },
    { key: "created_at", label: "생성일", flex: 2 },
    { key: "is_active", label: "활성화", flex: 1 },
    { key: "aggregate_icons", label: "집계", flex: 1 },
    { key: "delete_icons", label: "삭제", flex: 1 },
  ];

  const { data, refetch } = useApiKeyList(params);

  const searchHandler = (e) => {
    params["search"] = e.target.value;
    params["page"] = 1;
  };

  const pageMoveHandler = (page) => {
    params["page"] = page;
    refetch(params);
  };

  const deleteConfirm = (e, obj) => {
    e.stopPropagation();
    setDeleteModal({ visible: true, obj: obj });
  };

  const { mutate } = useApiKeyDelete();

  const deleteHandler = useCallback(
    (obj) => {
      const projectIncludeObj = Object.assign({}, obj, {
        projectId: projectId,
      });
      setDeleteModal({ visible: false, obj: {} });

      mutate(projectIncludeObj, {
        onSuccess: (data) => {
          setAlertSign("API 키가 삭제되었습니다.");
        },
      });
    },
    [mutate]
  );

  const aggregateModalOpen = (e, obj) => {
    e.stopPropagation();
    setAggregateModal({ visible: true, obj: obj });
  };
  return (
    <>
      <ListTable
        buttonName="API 키 생성"
        addButtonLink={`/Project/${projectId}/AddAPIKey`}
        detailLink={`/Project/${projectId}/APIKey`}
        changeSearchValue={searchHandler}
        searchClick={(e) => refetch(params)}
        ths={ths}
        tds={data?.data.results}
        page={params.page}
        count={data?.data.count}
        pageMoveHandler={pageMoveHandler}
        deleteHandler={deleteConfirm}
        aggregateModalHandler={aggregateModalOpen}
        tableKey={"apikey-list"}
        tabState={"tab-apikey"}
      />
      <DeleteModal
        display={deleteModal.visible}
        close={() => setDeleteModal({ visible: false, obj: {} })}
        modalTitle={"API 키 삭제"}
        modalTxt={"API 키를 삭제하시겠습니까?"}
        boxContents={
          deleteModal
            ? [
                { key: "API 키 이름", value: deleteModal.obj.name },
                { key: "API 키 설명", value: deleteModal.obj.desc },
                { key: "API 키", value: deleteModal.obj.value },
                {
                  key: "활성화 상태",
                  value: deleteModal.obj.is_active ? "활성화" : "비활성화",
                },
              ]
            : []
        }
        announces={[
          "삭제 이후 복구가 불가능합니다.",
          "삭제 시 연결된 항목들도 함께 제거됩니다.",
        ]}
        confirmButtonClick={() => deleteHandler(deleteModal.obj)}
      />
      {aggregateModal.visible && (
        <AggregateModal
          modalDP={aggregateModal.visible}
          close={(e) => setAggregateModal(!aggregateModal)}
          id={aggregateModal.obj.id}
          projectId={aggregateModal.obj.project}
        />
      )}
    </>
  );
}

export default APIKeyList;
