import React, { useState } from "react";
import styled from "styled-components";
import { login } from "hooks/auth";
import MetaTag from "MetaTag";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import { useMutation } from "react-query";
import BackImg from "assets/png/login_background.png";
import Logo from "assets/svg/logo_with_text.svg";
import useAlert from "../../hooks/useAlert";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  background-image: url(${(props) => props.backImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Box = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10vh;
  margin-left: 8%;
  padding: 40px;
  width: 50vh;
  height: 10vh;
  background-color: #ffffff;
  border-radius: 32px;
  box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.12);
  opacity: 0;
  overflow: hidden;
`;

const LogoWrap = styled.div`
  height: 56px;
`;

const FormWrap = styled.div`
  margin-bottom: 80px;
  width: 100%;
  opacity: 0;
`;

const Title = styled.h1`
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: 700;
  font-family: "Gmarket Sans";
  color: var(--color-BoldGreen);
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 56px;
  width: 100%;
  height: 136px;
`;

const RightWrap = styled.div`
  width: 100%;
  text-align: center;
`;

const RightText = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-LightGreen);
`;

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setAlert } = useAlert();
  const loginMutation = useMutation(login, {
    onSuccess: (data, variables, context) => {
      sessionStorage.setItem("token", data.auth_token);
      window.location = "/Project";
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        const errors = error.response.data;
        if (errors.username) {
          setAlert("fail", false, "로그인 실패", "아이디를 입력해주세요.");
        } else if (errors.password) {
          setAlert("fail", false, "로그인 실패", "비밀번호를 입력해주세요.");
        } else if (errors.non_field_errors) {
          setAlert("fail", false, "로그인 실패", errors.non_field_errors);
        }
      } else {
        setAlert(
          "fail",
          false,
          "서버 오류",
          "서버와의 통신에 실패했습니다. 관리자에게 문의해주세요."
        );
      }
    },
  });

  const loginHandler = () => {
    loginMutation.mutate({ username, password });
  };

  return (
    <>
      <MetaTag title="로그인" />
      <Container backImg={BackImg}>
        <Box className="login_box">
          <LogoWrap>
            <img src={Logo} />
          </LogoWrap>
          <FormWrap className="form_wrap">
            <Title>로그인</Title>
            <InputWrap>
              <Input
                placeholder="아이디를 입력해주세요"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onEnterKeyPress={(e) => loginHandler()}
              />
              <Input
                placeholder="비밀번호를 입력해주세요"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onEnterKeyPress={(e) => loginHandler()}
              />
            </InputWrap>
            <Button
              width="100%"
              bgColor="var(--color-NormalGreen)"
              hvColor="var(--color-EmphasisGreen)"
              txtColor="#FFFFFF"
              buttonName="로그인"
              onClick={(e) => loginHandler()}
            />
          </FormWrap>
          <RightWrap>
            <RightText>
              &#40;C&#41; DataNSoft Inc. All rights reserved.
            </RightText>
          </RightWrap>
        </Box>
      </Container>
    </>
  );
}

export default Login;
