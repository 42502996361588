import React from "react";
import styled from "styled-components";

const Container = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: ${(props) => props.thFlex};
  width: 100%;
  height: 100%;
  text-align: center;
`;

const ThText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: var(--color-LightGreen);
`;

function Th({ thFlex, thText }) {
  return (
    <Container thFlex={thFlex}>
      <ThText>{thText}</ThText>
    </Container>
  );
}

export default Th;
