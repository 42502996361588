import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
  max-width: ${(props) => props.width};

  &:last-child {
    margin-bottom: 0;
  }
`;

const TitleWrap = styled.div`
  min-width: ${(props) => props.titleWidth};
`;

const Title = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: var(--color-GreyGreen);
`;

const ContentWrap = styled.div`
  width: max-content;
`;

const Content = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: var(--color-DarkGreen);
`;

function InfoItem({ width, titleWidth, itemTitle, itemContent }) {
  return (
    <Container width={width}>
      <TitleWrap titleWidth={titleWidth}>
        <Title>{itemTitle}</Title>
      </TitleWrap>
      <ContentWrap>
        <Content>{itemContent}</Content>
      </ContentWrap>
    </Container>
  );
}

export default InfoItem;
