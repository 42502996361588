import React, { useEffect, useState } from "react";
import styled from "styled-components";

import CheckIcon from "../../../assets/svg/icon_check.svg";

const Container = styled.div`
  position: fixed;
  top: 80px;
  left: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  padding-right: 24px;
  width: fit-content;
  height: 80px;
  border-radius: 16px;
  background-color: var(--color-EmphasisGreen);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  transform: translateX(-50%);
  opacity: 0;
  z-index: 10000;
`;

const CheckWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
`;

const CheckIconWrap = styled.div`
  width: 32px;
  height: 32px;
  background-image: url(${(props) => props.checkIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const AlertText = styled.p`
  position: relative;
  top: 2px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Gmarket Sans";
  color: #ffffff;
`;

function AlertSign({ alertText, display }) {
  return (
    <Container className={"alertSign"} style={{ display: false }}>
      <CheckWrap>
        <CheckIconWrap checkIcon={CheckIcon} />
      </CheckWrap>
      <AlertText>{alertText}</AlertText>
    </Container>
  );
}

export default AlertSign;
