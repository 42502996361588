import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import MetaTag from "MetaTag";
import PageLayout from "components/templates/PageLayout";
import ProjectHeader from "components/organisms/ProjectHeader";
import AddModifyLayout from "components/templates/AddModifyLayout";
import InputwithTitle from "components/molecules/InputWithTitle";
import TextareawithTitle from "components/molecules/TextareaWithTitle";
import { useNavigate, useParams } from "react-router-dom";
import useAlert from "../../../hooks/useAlert";
import { apikeyValidation } from "../../../utils/validation";
import {
  useApiKeyDetail,
  useApiKeyUpdate,
} from "../../../hooks/queries/useApiKeys";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-itmes: center;
  margin-bottom: 24px;
  width: 100%;
`;

function ModifyAPIKey() {
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();
  const { projectId, id } = useParams();

  const [apikey, setApiKey] = useState({
    id: 0,
    name: "",
    desc: "",
    projectId: projectId,
  });

  const [validate, setValidate] = useState(false);
  const { data } = useApiKeyDetail({ id: id, projectId: projectId });

  useEffect(() => {
    if (data) {
      setApiKey(Object.assign(data.data, { projectId: projectId }));
    }
  }, [data]);

  const { mutate } = useApiKeyUpdate();
  const updateHandler = useCallback(() => {
    mutate(apikey, {
      onSuccess: (data) => {
        navigate(`/Project/${projectId}/ApiKey/${id}`);
        setAlertSign("API Key 수정이 완료되었습니다.");
      },
    });
  }, [apikey]);

  const inputHandler = (e) => {
    let name = e.target.name;
    const currentStateCopy = apikey;
    currentStateCopy[name] = e.target.value;
    setApiKey(currentStateCopy);

    setValidate(apikeyValidation(apikey));
  };
  return (
    <>
      <MetaTag title="API키 수정" />
      <PageLayout>
        <ProjectHeader />
        <AddModifyLayout
          pageTitle="API 키 수정"
          buttonName="저장"
          buttonClick={updateHandler}
          buttonStatus={!validate}
          breadCrumbs={[
            {
              pageName: "API 키 관리",
              url: { url: `/Project/${projectId}`, state: "tab-apikey" },
            },
            { pageName: "API 키 수정", url: "" },
          ]}
          tabState={"tab-apikey"}
        >
          <Container>
            <Row>
              <InputwithTitle
                width="100%"
                inputTitle="API 키 이름"
                placeholder="API 키 이름을 입력해주세요"
                tooltipTxt="API 키의 명칭"
                value={apikey.name}
                name={"name"}
                onChange={inputHandler}
                maxLength={50}
              />
            </Row>
            <Row>
              <TextareawithTitle
                width="100%"
                areaHeight="120px"
                EsnSignDp="none"
                inputTitle="API 키 설명"
                placeholder="API 키 설명을 입력해주세요"
                tooltipTxt="API 키 상세 내용"
                value={apikey.desc}
                name={"desc"}
                onChange={inputHandler}
                maxLength={500}
              />
            </Row>
            <Row>
              <InputwithTitle
                width="100%"
                inputTitle="API 키"
                tooltipTxt="API 키의 값"
                value={apikey.value}
                disabled={true}
                EsnSignDp="none"
              />
            </Row>
          </Container>
        </AddModifyLayout>
      </PageLayout>
    </>
  );
}

export default ModifyAPIKey;
