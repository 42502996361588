import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const Container = styled.input`
  padding: 0 24px;
  width: 100%;
  min-width: 200px;
  height: 60px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-BoldGreen);
  border-radius: 16px;
  border: solid 1px #dbede7;
  box-sizing: border-box;

  ::placeholder {
    color: var(--color-Placeholder);
  }

  &:focus-visible {
    outline: none;
    border-color: var(--color-EmphasisGreen);
  }

  ${(props) =>
    props.value
      ? css`
          border-color: var(--color-EmphasisGreen);
        `
      : css`
          border-color: #dbede7;
        `}
  ${(props) =>
    props.searchInput &&
    css`
      border-color: var(--color-NormalGreen);
    `}
`;

function Input({
  placeholder,
  type,
  disabled,
  onChange,
  name,
  onEnterKeyPress,
  maxLength,
  value,
  searchInput,
}) {
  const [text, setText] = useState("");
  const [status, setStatus] = useState(false);
  const changeHandler = (event) => {
    setText(event.target.value);
    onChange(event);
  };

  useEffect(() => {
    setStatus(disabled);
  }, [disabled]);

  const handleOnKeyPress = (e) => {
    if (onEnterKeyPress) {
      if (e.key === "Enter") {
        onEnterKeyPress();
      }
    }
  };

  return (
    <Container
      name={name}
      placeholder={placeholder}
      value={text || value || ""}
      onChange={changeHandler}
      type={type}
      disabled={status}
      onKeyPress={handleOnKeyPress}
      maxLength={maxLength}
      searchInput={searchInput}
    ></Container>
  );
}

export default Input;
