import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 12px;
  text-align: center;
  background-color: ${(props) =>
    props.selected ? "var(--color-EmphasisGreen) !important" : "#EEF8F7"};
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover {
    background-color: #cff0e4;
  }
`;

const Number = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.selected ? "#FFFFFF" : "#89A0BF")};
  line-height: 40px !important;
`;

function PageButton({ num, selected, onClick }) {
  return (
    <Container selected={selected} onClick={onClick}>
      <Number selected={selected}>{num}</Number>
    </Container>
  );
}

export default PageButton;
