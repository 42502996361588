import React from "react";
import styled from "styled-components";

const Container = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: "Gmarket Sans";
  color: #283782;
`;

function ModalTitleText({ modalTitle }) {
  return <Container>{modalTitle}</Container>;
}

export default ModalTitleText;
