import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "components/organisms/ConnectTable";
import Modal from "components/templates/addMappedModal";
import DeleteModal from "../../../../components/templates/DeleteModal";
import useAlert from "../../../../hooks/useAlert";
import {
  useUsagePlanEndPointCreate,
  useUsagePlanEndPointDelete,
  useUsagePlanEndPoints,
} from "../../../../hooks/queries/useUsagePlanEndPoint";
import { useEndpointList } from "../../../../hooks/queries/useEndpoints";
import useAlertSign from "../../../../hooks/useAlertSign";

function UsagePlanEndPointList() {
  const { projectId, id } = useParams();
  // 엔드포인트 연결 추가 모달 visible
  const [mapModal, setMapModal] = useState(false);
  // 엔드포인트 연결추가 체크 항목 (ID: array)
  const [addEndpointList, setAddEndPointList] = useState([]);
  // 엔드포인트 연결제거 체크 항목 (ID: array)
  const [removeEndpointList, setRemoveEndpointList] = useState([]);
  // 삭제 컨펌 모달
  const [deleteModal, setDeleteModal] = useState(false);
  // tab
  const [searchValue, setSearchValue] = useState("");
  const [unUsedEndPoints, setUnUsedEndPoints] = useState([]);

  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();

  const usageplanThs = [
    { key: "checkbox", label: "checkbox" },
    { key: "index", label: "번호", flex: 1 },
    { key: "name", label: "엔드포인트 이름", flex: 5 },
    { key: "desc", label: "엔드포인트 설명", flex: 5 },
  ];

  // 매핑 관계
  const mappedData = useUsagePlanEndPoints({ id: id, projectId: projectId });
  const UsagePlanEndPoints = mappedData.data?.data || [];

  // 전체 엔드포인트
  const endpoint = useEndpointList({
    id: id,
    projectId: projectId,
    no_page: true,
  });
  const endPointList = endpoint.data?.data || [];

  useEffect(() => {
    if (endPointList && !endPointList.results) {
      let addEndPoints =
        endPointList.filter(
          (row) =>
            UsagePlanEndPoints.findIndex(
              (endpoint) => endpoint.id === row.id
            ) === -1
        ) || [];
      if (unUsedEndPoints.length !== addEndPoints.length) {
        let plans = new Set(addEndPoints);
        setUnUsedEndPoints([...plans]);
      }
    }
  }, [UsagePlanEndPoints, endPointList]);

  // 사용량 계획 연결 추가
  const mapCreate = useUsagePlanEndPointCreate();

  const addHandler = (e) => {
    if (addEndpointList.length < 1) {
      setAlert(
        "fail",
        false,
        "엔드포인트 연결추가 오류",
        "선택된 엔드포인트가 없습니다. 엔드포인트를 선택해주세요."
      );
    } else {
      addEndpointList.map((row) => {
        mapCreate.mutate(
          { apiendpoint: row, apiusageplan: id, projectId: projectId },
          {
            onSuccess: ({ data }) => {
              setMapModal(false);
              setAlertSign("엔드포인트 추가를 완료하였습니다.");
              setAddEndPointList([]);
            },
          }
        );
      });
    }
  };

  // 사용량 계획 연결 제거
  const removeModalHandler = () => {
    if (removeEndpointList.length < 1) {
      setAlert(
        "fail",
        false,
        "엔드포인트 연결제거 오류",
        "선택된 엔드포인트가 없습니다. 엔드포인트를 선택해주세요."
      );
    } else {
      setDeleteModal(!deleteModal);
    }
  };

  const mapDelete = useUsagePlanEndPointDelete();
  const removeQuery = () => {
    removeEndpointList.map((row) => {
      mapDelete.mutate(
        { apiendpoint: row, apiusageplan: id, projectId: projectId },
        {
          onSuccess: ({ data }) => {
            setDeleteModal(false);
            setAlertSign("엔드포인트 연결 제거를 완료하였습니다.");
            setRemoveEndpointList((removeEndpointList) =>
              removeEndpointList.splice(0)
            );
          },
        }
      );
    });
  };

  const removeAllCheckHandler = (checked) => {
    let planIds = [];
    if (checked) {
      planIds = removeEndpointList;
      UsagePlanEndPoints.map((row) => planIds.push(row.id));
      let plans = new Set(planIds);
      setRemoveEndpointList([...plans]);
    } else {
      setRemoveEndpointList(removeEndpointList.filter((arr) => {}));
    }
  };

  const addAllCheckHandler = (checked) => {
    let planIds = addEndpointList;
    if (checked) {
      unUsedEndPoints.map((row) => planIds.push(row.id));
      let plans = new Set(planIds);
      setAddEndPointList([...plans]);
    } else {
      setAddEndPointList(addEndpointList.filter((arr) => {}));
    }
  };

  const addCheckBoxHandler = (e) => {
    let plans = [];
    const targetId = Number(e.target.name);
    if (e.target.checked) {
      plans = [...addEndpointList, targetId];
    } else {
      plans = addEndpointList.filter((plan) => plan !== targetId);
    }
    plans = new Set(plans);
    setAddEndPointList([...plans]);
  };

  const removeCheckBoxHandler = (e) => {
    let plans = [];
    const targetId = Number(e.target.name);
    if (e.target.checked) {
      plans = [...removeEndpointList, targetId];
    } else {
      plans = removeEndpointList.filter((plan) => plan !== targetId);
    }
    plans = new Set(plans);
    setRemoveEndpointList([...plans]);
  };

  const searchClick = () => {
    let unUsedList =
      endPointList.filter(
        (row) =>
          UsagePlanEndPoints.findIndex((endpoint) => endpoint.id === row.id) ===
          -1
      ) || [];

    unUsedList = unUsedList.filter(
      (row) =>
        String(row.id).includes(searchValue) ||
        String(row.name).includes(searchValue) ||
        String(row.desc).includes(searchValue)
    );
    unUsedList = new Set(unUsedList);
    setUnUsedEndPoints([...unUsedList]);
    setAddEndPointList([]);
  };

  return (
    <>
      <Table
        ths={usageplanThs}
        tds={UsagePlanEndPoints}
        count={UsagePlanEndPoints?.length || 0}
        countText={"연결된 엔드포인트"}
        addClickFn={() => setMapModal(true)}
        removeClickFn={removeModalHandler}
        checkFn={removeCheckBoxHandler}
        allCheckHandler={(e) => removeAllCheckHandler(e.target.checked)}
        checkedList={removeEndpointList || []}
        tableKey={"mapped-usageplan-endpoint"}
      />

      <Modal
        modalDP={mapModal}
        modalTitle="엔드포인트 연결 추가"
        modalTxt="추가할 엔드포인트를 선택해주세요"
        btnName="추가"
        infoItem1="기존 엔드포인트"
        infoItem2="선택한 엔드포인트"
        infoContent1={UsagePlanEndPoints?.length || 0}
        close={() => setMapModal(false)}
        checkFn={addCheckBoxHandler}
        confirmButtonClick={(e) => addHandler()}
        ths={usageplanThs}
        tds={unUsedEndPoints}
        allCheckHandler={(e) => addAllCheckHandler(e.target.checked)}
        checkedList={addEndpointList || []}
        changeSearchValue={(e) => setSearchValue(e.target.value)}
        searchClick={(e) => searchClick()}
        tableKey={"add-usageplan-endpoint"}
      />

      <DeleteModal
        display={deleteModal}
        close={() => setDeleteModal(!deleteModal)}
        modalTitle={"엔드포인트 연결제거"}
        modalTxt={"선택한 엔드포인트 연결을 제거하시겠습니까?"}
        boxContents={
          deleteModal
            ? [{ key: "선택한 엔드포인트", value: removeEndpointList.length }]
            : []
        }
        announces={[
          "연결관계만 제거되며, 실제 엔드포인트는 삭제되지 않습니다.",
        ]}
        confirmButtonClick={removeQuery}
      />
    </>
  );
}

export default UsagePlanEndPointList;
