export const projectValidation = (project) => {
  if (!project.name) {
    // 프로젝트 이름 required 체크
    return false;
  } else if (project.name.length > 50) {
    // 프로젝트 이름 최대 글자 수
    return false;
  } else if (!project.tz) {
    return false;
  } else {
    return true;
  }
};

export const endpointValidation = (endpoint) => {
  if (!endpoint.name) {
    return false;
  } else if (endpoint.name.length > 50) {
    return false;
  } else if (endpoint.desc.length > 500) {
    return false;
  } else if (!endpoint.url_src_path) {
    return false;
  } else if (!endpoint.method) {
    return false;
  } else if (!endpoint.url_tgt_full) {
    return false;
  } else {
    return true;
  }
};

export const usageplanValidation = (usageplan) => {
  if (!usageplan.name) {
    return false;
  } else if (usageplan.name.length > 50) {
    return false;
  } else if (usageplan.desc.length > 500) {
    return false;
  } else if (usageplan.quota_enabled === undefined) {
    return false;
  } else if (usageplan.throttling_enabled === undefined) {
    return false;
  } else {
    return true;
  }
};

export const apikeyValidation = (apikey) => {
  if (!apikey.name) {
    return false;
  } else if (apikey.name.length > 50) {
    return false;
  } else if (apikey.desc.length > 500) {
    return false;
  } else {
    return true;
  }
};
