import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.bgColor};
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover {
    background-color: ${(props) => props.hvColor};
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.imgUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

function SquareButton(props) {
  const { bgColor, hvColor, imgUrl, leftIcon, onClick, className } = props;
  return (
    <Container
      className={`buttons ${className}`}
      bgColor={bgColor}
      hvColor={hvColor}
      onClick={onClick}
    >
      <Icon imgUrl={imgUrl} leftIcon={leftIcon}></Icon>
    </Container>
  );
}

export default SquareButton;
