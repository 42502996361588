import React from "react";
import styled from "styled-components";

import Unit from "../../atoms/AnnouncementUnit";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  width: auto;
  max-width: ${(props) => props.maxWidth};
  background-color: var(--color-BgBlue);
  border-radius: 16px;
`;

function AnnouncementBox({ maxWidth, announces }) {
  return (
    <Container maxWidth={maxWidth}>
      {(announces || []).map((announce) => (
        <Unit className="last" key={announce} infoText={announce} />
      ))}
    </Container>
  );
}

export default AnnouncementBox;
