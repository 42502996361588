import React from "react";
import styled from "styled-components";

import TabMenuDetail from "../../molecules/TabMenuDetail";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  width: calc(100% - 620px);
  height: 100%;
  min-height: 832px;
  background-color: #ffffff;
  border-radius: 32px;
`;

const BoxHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  margin-top: 8px;
  width: 100%;
  height: 100%;
`;

function TabBox({ tabs, children, selectedTab, onClick }) {
  return (
    <Container>
      <BoxHeader>
        <TabMenuDetail
          tabs={tabs}
          selectedTab={selectedTab}
          onClick={onClick}
        />
      </BoxHeader>
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  );
}

export default TabBox;
