import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import MetaTag from "MetaTag";
import PageLayout from "components/templates/PageLayout";
import ProjectHeader from "components/organisms/ProjectHeader";
import AddModifyLayout from "components/templates/AddModifyLayout";
import InputwithTitle from "components/molecules/InputWithTitle";
import TextareawithTitle from "components/molecules/TextareaWithTitle";
import SelectwithTitle from "components/molecules/SelectWithTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useProjectDetail, useProjectUpdate } from "hooks/queries/useProjects";
import { projectValidation } from "utils/validation";
import useAlert from "hooks/useAlert";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-itmes: center;
  margin-bottom: 24px;
  width: 100%;
`;

function ModifyProject({ nameSpaceHolder }) {
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();
  const [project, setProject] = useState({
    id: 0,
    name: "",
    desc: "",
    tz: "UTC",
    max_endpoints: 5,
  });
  const [validate, setValidate] = useState(true);

  const { projectId } = useParams();
  const { data } = useProjectDetail(projectId);
  useEffect(() => {
    if (data) {
      setProject(data.data);
    }
  }, [data]);

  const { mutate } = useProjectUpdate();

  const updateHandler = useCallback(() => {
    mutate(project, {
      onSuccess: (data) => {
        navigate(`/Project/${projectId}`);
        setAlertSign("프로젝트 수정이 완료되었습니다.");
      },
    });
  }, [project]);

  const inputHandler = (e) => {
    let name = e.target.name;
    const currentStateCopy = project;
    currentStateCopy[name] = e.target.value;
    setProject(currentStateCopy);

    setValidate(projectValidation(project));
  };

  return (
    <>
      <MetaTag title="프로젝트 수정" />
      <PageLayout>
        <ProjectHeader />
        <AddModifyLayout
          pageTitle="프로젝트 수정"
          buttonName="수정"
          buttonClick={updateHandler}
          buttonStatus={!validate}
          tabDP="none"
          breadCrumbs={[{ pageName: "프로젝트 수정", url: "" }]}
        >
          <Container>
            <Row>
              <InputwithTitle
                width="100%"
                inputTitle="프로젝트 이름"
                placeholder="프로젝트 이름을 입력해주세요"
                tooltipTxt="프로젝트 명칭"
                value={project.name}
                name={"name"}
                onChange={inputHandler}
                maxLength={50}
              />
            </Row>
            <Row>
              <TextareawithTitle
                width="100%"
                areaHeight="120px"
                EsnSignDp="none"
                inputTitle="프로젝트 설명"
                placeholder="프로젝트 설명을 입력해주세요"
                tooltipTxt="프로젝트 상세 내용"
                value={project.desc}
                name={"desc"}
                onChange={inputHandler}
                maxLength={500}
              />
            </Row>
            <Row>
              <InputwithTitle
                width="100%"
                inputTitle="네임 스페이스"
                EsnSignDp="none"
                tooltipTxt={`요청 URL에서 ${process.env.REACT_APP_PROXY_BASE_URL}/<namespace>/<endpoint> 형태로 활용됩니다. 임의의 영문/숫자의 조합으로 시스템이 자동생성합니다.`}
                disabled={true}
                value={project.ns}
              />
            </Row>
            <Row>
              <SelectwithTitle
                width="calc(50% - 10px)"
                selectTitle="타임존"
                tooltipTxt="호출량을 집계할 기준시간대"
                name={"tz"}
                value={project.tz}
                onChange={inputHandler}
                items={["UTC", "Asia/Seoul"]}
              />
              <InputwithTitle
                width="calc(50% - 10px)"
                EsnSignDp="none"
                inputTitle="최대 엔드포인트"
                placeholder="최대 엔드포인트 값을 입력해주세요"
                name={"max_endpoints"}
                tooltipTxt="악의적인 사용자를 대비하기 위한 최대 생성가능 개수"
                value={project.max_endpoints}
                onChange={inputHandler}
                type={"number"}
                disabled={true}
              />
            </Row>
          </Container>
        </AddModifyLayout>
      </PageLayout>
    </>
  );
}

export default ModifyProject;
