import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "components/organisms/ConnectTable";
import Modal from "components/templates/addMappedModal";
import DeleteModal from "../../../../components/templates/DeleteModal";
import useAlert from "../../../../hooks/useAlert";
import {
  useUsagePlanApiKeyCreate,
  useUsagePlanApiKeyDelete,
  useUsagePlanApiKeys,
} from "../../../../hooks/queries/useUsagePlanApiKeys";
import { useApiKeyList } from "../../../../hooks/queries/useApiKeys";
import useAlertSign from "../../../../hooks/useAlertSign";

function UsagePlanApiKeyList() {
  const { projectId, id } = useParams();

  // 삭제 컨펌 모달
  const [deleteModal, setDeleteModal] = useState(false);

  // 키 연결 추가 모달 visible
  const [mapModal, setMapModal] = useState(false);
  const [addApiKeyList, setAddApiKeyList] = useState([]);
  const [removeApiKeyList, setRemoveApiKeyList] = useState([]);

  // tab
  const [searchValue, setSearchValue] = useState("");
  const [unUsedApiKeys, setUnUsedApiKeys] = useState([]);

  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();

  const apiKeyThs = [
    { key: "checkbox", label: "checkbox" },
    { key: "index", label: "번호", flex: 1 },
    { key: "name", label: "API 키 이름", flex: 5 },
    { key: "desc", label: "API 키 설명", flex: 5 },
  ];

  // 매핑 관계
  const mappedData = useUsagePlanApiKeys({ id: id, projectId: projectId });
  const UsagePlanApiKeys = mappedData.data?.data || [];

  // 전체 엔드포인트
  const apikey = useApiKeyList({ id: id, projectId: projectId, no_page: true });
  const apiKeyList = apikey.data?.data || [];

  useEffect(() => {
    if (apiKeyList && !apiKeyList.results) {
      let addEndPoints =
        apiKeyList.filter(
          (row) =>
            UsagePlanApiKeys.findIndex((endpoint) => endpoint.id === row.id) ===
            -1
        ) || [];
      if (unUsedApiKeys.length !== addEndPoints.length) {
        let plans = new Set(addEndPoints);
        setUnUsedApiKeys([...plans]);
      }
    }
  }, [UsagePlanApiKeys, apiKeyList]);

  // 사용량 계획 연결 추가
  const mapCreate = useUsagePlanApiKeyCreate();

  const addHandler = (e) => {
    if (addApiKeyList.length < 1) {
      setAlert(
        "fail",
        false,
        "API 키 연결추가 오류",
        "선택된 API 키가 없습니다. API 키를 선택해주세요."
      );
    } else {
      addApiKeyList.map((row) => {
        mapCreate.mutate(
          { apikey: row, apiusageplan: id, projectId: projectId },
          {
            onSuccess: ({ data }) => {
              setMapModal(false);
              setAlertSign("API 키 추가를 완료하였습니다.");
              setAddApiKeyList([]);
            },
          }
        );
      });
    }
  };

  // 사용량 계획 연결 제거
  const removeModalHandler = () => {
    if (removeApiKeyList.length < 1) {
      setAlert(
        "fail",
        false,
        "API 키 연결제거 오류",
        "선택된 API 키가 없습니다. API 키를 선택해주세요."
      );
    } else {
      setDeleteModal(!deleteModal);
    }
  };

  const mapDelete = useUsagePlanApiKeyDelete();
  const removeQuery = () => {
    removeApiKeyList.map((row) => {
      mapDelete.mutate(
        { apikey: row, apiusageplan: id, projectId: projectId },
        {
          onSuccess: ({ data }) => {
            setDeleteModal(false);
            setAlertSign("API 키 연결제거를 완료하였습니다.");
            setRemoveApiKeyList((removeApiKeyList) =>
              removeApiKeyList.splice(0)
            );
          },
        }
      );
    });
  };

  const removeAllCheckHandler = (checked) => {
    let planIds = [];
    if (checked) {
      planIds = removeApiKeyList;
      UsagePlanApiKeys.map((row) => {
        planIds.push(row.id);
      });
      let plans = new Set(planIds);
      setRemoveApiKeyList([...plans]);
    } else {
      setRemoveApiKeyList(removeApiKeyList.filter((arr) => {}));
    }
  };

  const addAllCheckHandler = (checked) => {
    let planIds = addApiKeyList;
    if (checked) {
      unUsedApiKeys.map((row) => {
        planIds.push(row.id);
      });
      let plans = new Set(planIds);
      setAddApiKeyList([...plans]);
    } else {
      setAddApiKeyList(addApiKeyList.filter((arr) => {}));
    }
  };

  const addCheckBoxHandler = (e) => {
    let plans = [];
    const targetId = Number(e.target.name);
    if (e.target.checked) {
      plans = [...addApiKeyList, targetId];
    } else {
      plans = addApiKeyList.filter((plan) => plan !== targetId);
    }
    plans = new Set(plans);
    setAddApiKeyList([...plans]);
  };

  const removeCheckBoxHandler = (e) => {
    let plans = [];
    const targetId = Number(e.target.name);
    if (e.target.checked) {
      plans = [...removeApiKeyList, targetId];
    } else {
      plans = removeApiKeyList.filter((plan) => plan !== targetId);
    }
    console.log(plans);
    plans = new Set(plans);
    setRemoveApiKeyList([...plans]);
  };

  const searchClick = () => {
    let unUsedList =
      apiKeyList.filter(
        (row) =>
          UsagePlanApiKeys.findIndex((endpoint) => endpoint.id === row.id) ===
          -1
      ) || [];

    unUsedList = unUsedList.filter(
      (row) =>
        String(row.id).includes(searchValue) ||
        String(row.name).includes(searchValue) ||
        String(row.desc).includes(searchValue)
    );
    unUsedList = new Set(unUsedList);
    setUnUsedApiKeys([...unUsedList]);
    setAddApiKeyList([]);
  };

  return (
    <>
      <Table
        ths={apiKeyThs}
        tds={UsagePlanApiKeys}
        count={UsagePlanApiKeys?.length || 0}
        countText={"연결된 API 키"}
        addClickFn={() => setMapModal(true)}
        removeClickFn={removeModalHandler}
        checkFn={removeCheckBoxHandler}
        allCheckHandler={(e) => removeAllCheckHandler(e.target.checked)}
        checkedList={removeApiKeyList || []}
        tableKey={"mapped-usageplan-apikey"}
      />

      <Modal
        modalDP={mapModal}
        modalTitle="API 키 연결 추가"
        modalTxt="추가할 API 키를 선택해주세요"
        btnName="추가"
        infoItem1="기존 API 키"
        infoItem2="선택한 API 키"
        infoContent1={UsagePlanApiKeys?.length || 0}
        close={() => setMapModal(false)}
        checkFn={addCheckBoxHandler}
        confirmButtonClick={(e) => addHandler()}
        ths={apiKeyThs}
        tds={unUsedApiKeys}
        allCheckHandler={(e) => addAllCheckHandler(e.target.checked)}
        checkedList={addApiKeyList || []}
        changeSearchValue={(e) => setSearchValue(e.target.value)}
        searchClick={(e) => searchClick()}
        tableKey={"add-usageplan-apikey"}
      />

      <DeleteModal
        display={deleteModal}
        close={() => setDeleteModal(!deleteModal)}
        modalTitle={"API 키 연결제거"}
        modalTxt={"선택한 API 키 연결을 제거하시겠습니까?"}
        boxContents={
          deleteModal
            ? [{ key: "선택한 API 키", value: removeApiKeyList.length }]
            : []
        }
        announces={["연결관계만 제거되며, 실제 API 키는 삭제되지 않습니다."]}
        confirmButtonClick={removeQuery}
      />
    </>
  );
}

export default UsagePlanApiKeyList;
