import React from "react";
import styled from "styled-components";

import NaviItem from "../../atoms/NavItem";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const PageName = styled.a`
  font-size: 14px;
  font-weight: 400;
  color: #719dba;
  cursor: pointer;
`;

function Navigation({ breadCrumbs }) {
  const navigate = useNavigate();
  return (
    <Container>
      <PageName onClick={(e) => navigate("/Project")}>프로젝트 관리</PageName>
      {breadCrumbs.map((breadCrumb) => (
        <NaviItem pageName={breadCrumb.pageName} pageUrl={breadCrumb.url} />
      ))}
    </Container>
  );
}

export default Navigation;
