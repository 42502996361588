import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";

import MetaTag from "MetaTag";
import ProjectHeader from "components/organisms/ProjectHeader";
import OptionButton from "components/molecules/ProjectButton";
import BackButton from "components/atoms/BackButton";
import PageLayout from "components/templates/PageLayout";
import PageTitle from "components/organisms/PageTitle";
import DetailBox from "components/organisms/DetailBox";
import EmphasisTitle from "components/atoms/EmphasisTitle";
import Tooltip from "components/atoms/Tooltip";
import DetailInfoItem from "components/molecules/DetailInfoItem";
import UsageSign from "components/atoms/UsageSign";
import TabMenu from "components/molecules/TabMenu";
import { useLocation } from "react-router-dom";

import TabIcon1 from "assets/svg/tab_icon_1.svg";
import TabIcon2 from "assets/svg/tab_icon_2.svg";
import TabIcon3 from "assets/svg/tab_icon_3.svg";
import ModifyIcon from "assets/svg/icon_modify.svg";
import DeleteIcon from "assets/svg/icon_delete.svg";
import useAlert from "../../../hooks/useAlert";
import DeleteModal from "../../../components/templates/DeleteModal";
import {
  useUsagePlanDelete,
  useUsagePlanDetail,
} from "../../../hooks/queries/useUsagePlans";
import TabBox from "../../../components/organisms/TabBox";
import UsagePlanEndPointList from "./UsagePlanEndPointList";
import UsagePlanApiKeyList from "./UsagePlanApiKeyList";
import useAlertSign from "../../../hooks/useAlertSign";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
  padding: 0 40px 40px 40px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ContentsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  box-sizing: border-box;
  margin-top: 24px;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
`;

const ColumnSide = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.h5`
  margin-bottom: 4px;
  font-size: 15px;
  font-weught: 400;
  color: var(--color-GreyGreen);
`;

const UsagePlanName = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: "Gmarket Sans";
`;

const BoxButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;

const UsagePlanDescription = styled.p`
  width: 100%;
  max-width: 100%;
`;

const InfoWrap = styled.div`
  displat: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  width: 100%;

  & > div:nth-child(2) {
    margin-bottom: 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-itmes: center;
  margin-bottom: 24px;
  width: 100%;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > h2 {
    margin-right: 8px;
  }
`;

function UsagePlanDetail() {
  const { projectId, id } = useParams();
  const { setAlert } = useAlert();
  const { setAlertSign } = useAlertSign();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("endpoint");
  const [deleteModal, setDeleteModal] = useState({ visible: false, obj: {} });

  const { data } = useUsagePlanDetail({ id: id, projectId: projectId });
  const usagePlan = data?.data;

  const deleteConfirm = (e, obj) => {
    e.stopPropagation();
    setDeleteModal({ visible: true, obj: obj });
  };

  const { mutate } = useUsagePlanDelete();
  const deleteHandler = useCallback(
    (obj) => {
      const projectIncludeObj = Object.assign({}, obj, {
        projectId: projectId,
      });
      setDeleteModal({ visible: false, obj: {} });

      mutate(projectIncludeObj, {
        onSuccess: (data) => {
          navigate(`/Project/${projectId}`, { state: "tab-usageplan" });
          setAlertSign("사용량 계획 삭제가 완료되었습니다.");
        },
      });
    },
    [mutate]
  );

  const [tabState, setTabState] = useState("tab-endpoint");

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setTabState(state);
    }
  }, []);

  // tab items
  const tabs = [
    {
      id: "tab-endpoint",
      iconUrl: TabIcon1,
      tabName: "엔드포인트 관리",
    },
    {
      id: "tab-usageplan",
      iconUrl: TabIcon2,
      tabName: "사용량계획 관리",
    },
    {
      id: "tab-apikey",
      iconUrl: TabIcon3,
      tabName: "API 키 관리",
    },
  ];

  return (
    <>
      <MetaTag title="사용량계획 상세정보" />
      <PageLayout>
        <ProjectHeader />

        <Container>
          <Header>
            <HeaderLeftSide>
              <BackButton
                link={(e) =>
                  navigate(`/Project/${projectId}`, { state: "tab-usageplan" })
                }
              />
              <PageTitle
                pageTitle="사용량계획 상세정보"
                breadCrumbs={[
                  {
                    pageName: "사용량계획 관리",
                    url: {
                      url: `/Project/${projectId}`,
                      state: "tab-usageplan",
                    },
                  },
                  { pageName: "사용량계획 상세정보", url: "" },
                ]}
              />
            </HeaderLeftSide>
            <TabMenu
              tabs={tabs}
              tabSelectedFn={(e) =>
                navigate(`/Project/${projectId}`, { state: e.target.id })
              }
              selected={"tab-usageplan"}
            />
          </Header>
          <ContentsWrap>
            {/* 왼쪽박스 start */}
            <DetailBox href="/ModifyUsagePlan">
              <HeaderRow>
                <ColumnSide>
                  <ContentTitle>사용량계획 이름</ContentTitle>
                  <UsagePlanName>{usagePlan?.name}</UsagePlanName>
                </ColumnSide>
                <BoxButtonWrap>
                  <Link
                    to={`/Project/${projectId}/ModifyUsagePlan/${id}`}
                    state={"tab-usageplan"}
                  >
                    <OptionButton svgUrl={ModifyIcon} />
                  </Link>
                  <OptionButton
                    svgUrl={DeleteIcon}
                    margin="0 0 0 16px"
                    onClick={(e) => deleteConfirm(e, usagePlan)}
                  />
                </BoxButtonWrap>
              </HeaderRow>
              <ColumnSide>
                <ContentTitle>사용량계획 설명</ContentTitle>
                <UsagePlanDescription>{usagePlan?.desc}</UsagePlanDescription>
              </ColumnSide>
              <InfoWrap>
                <Column>
                  <Row>
                    <LeftSide>
                      <EmphasisTitle barTxt="호출한도 설정" />
                      <Tooltip tooltipTxt="특정 API 키가 시간/일/월 단위로 호출할 수 있는 한도를 설정합니다." />
                    </LeftSide>
                    <UsageSign flag={usagePlan?.quota_enabled} />
                  </Row>
                  <DetailInfoItem
                    margin="0 0 12px 0"
                    contentName="호출단위시간"
                    contentText={usagePlan?.quota_period_type}
                  />
                  <DetailInfoItem
                    contentName="최대호출량(회)"
                    contentText={usagePlan?.quota_amount}
                  />
                </Column>
                <Column>
                  <Row>
                    <LeftSide>
                      <EmphasisTitle barTxt="부하방지 설정" />
                      <Tooltip
                        tooltipTxt="특정 API키의 호출에 대한 부하 판단 기준을 설정합니다. (예: 1,000ms 당 1 회)
                                                            부하라고 판단될 경우, 리셋될 때까지 호출을 방지합니다."
                      />
                    </LeftSide>
                    <UsageSign flag={usagePlan?.throttling_enabled} />
                  </Row>
                  <DetailInfoItem
                    margin="0 0 12px 0"
                    contentName="부하단위시간(ms)"
                    contentText={usagePlan?.throttling_period_msec}
                  />
                  <DetailInfoItem
                    contentName="최대부하량"
                    contentText={usagePlan?.throttling_amount}
                  />
                </Column>
              </InfoWrap>
            </DetailBox>
            {/* 왼쪽박스 end */}

            {/* 오른쪽박스 start */}
            <TabBox
              tabs={[
                { name: "엔드포인트", tabId: "endpoint" },
                { name: "API 키", tabId: "apikey" },
              ]}
              selectedTab={selectedTab}
              onClick={(e) => setSelectedTab(e.target.id)}
            >
              {selectedTab === "endpoint" ? (
                <UsagePlanEndPointList />
              ) : (
                <UsagePlanApiKeyList />
              )}
            </TabBox>
            {/* 오른쪽박스 end */}
          </ContentsWrap>
        </Container>

        <DeleteModal
          display={deleteModal.visible}
          close={() => setDeleteModal({ visible: false, obj: {} })}
          modalTitle={"사용량 계획 삭제"}
          modalTxt={"사용량 계획을 삭제하시겠습니까?"}
          boxContents={
            deleteModal
              ? [
                  { key: "사용량 계획 이름", value: deleteModal.obj.name },
                  { key: "사용량 계획 설명", value: deleteModal.obj.desc },
                  {
                    key: "연결된 엔드포인트",
                    value: deleteModal.obj.endpoint_count,
                  },
                  { key: "연결된 API 키", value: deleteModal.obj.apikey_count },
                ]
              : []
          }
          announces={[
            "삭제 이후 복구가 불가능합니다.",
            "삭제 시 연결된 항목들도 함께 제거됩니다.",
          ]}
          confirmButtonClick={() => deleteHandler(deleteModal.obj)}
        />
      </PageLayout>
    </>
  );
}

export default UsagePlanDetail;
