import React from "react";
import styled from "styled-components";

import TableHead from "../../molecules/TableHead";
import TableBody from "../../molecules/TableBody";
import InfoItem from "../../molecules/DetailInfoItem";
import SearchBar from "../../molecules/SearchBar";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 40px;
`;

const LeftSide = styled.div`
  display: flex;
`;

const RightSide = styled.div`
  display: flex;

  & > div:nth-child(2) {
    margin-left: 12px;
  }
`;

const TableSection = styled.div`
  margin: 12px 0 40px 0;
  width: 100%;
  flex-grow: 1;
`;

const Table = styled.table`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

function ModalTable({
  ths,
  tds,
  infoName1,
  infoName2,
  infoContent1,
  infoContent2,
  allCheckHandler,
  checkFn,
  checkedList,
  searchClick,
  changeSearchValue,
  tableKey,
}) {
  return (
    <Container>
      <TableHeader>
        <LeftSide>
          <InfoItem contentName={infoName1} contentText={infoContent1} />
          <InfoItem contentName={infoName2} contentText={infoContent2} />
        </LeftSide>
        <RightSide>
          <SearchBar
            placeholder="검색어를 입력해주세요"
            onChange={changeSearchValue}
            searchClick={searchClick}
          />
        </RightSide>
      </TableHeader>
      <TableSection>
        <Table>
          <TableHead
            ths={ths}
            tds={tds}
            tableKey={tableKey}
            allCheckHandler={allCheckHandler}
            value={
              checkedList.length !== 0 && checkedList.length === tds.length
            }
          />
          <TableBody
            tds={tds}
            ths={ths}
            tableKey={tableKey}
            tableHeight="480px"
            checkFn={checkFn}
            checkedList={checkedList}
          />
        </Table>
      </TableSection>
    </Container>
  );
}

export default ModalTable;
