import React from "react";
import styled from "styled-components";

import TooltipIcon from "../../../assets/svg/icon_tooltip.svg";
import TriangleImg from "../../../assets/svg/tooltip.svg";

const Container = styled.div`
  position: relative;
  bottom: 1px;
  display: ${(props) => props.display};
  width: auto;
  z-index: 10;

  &:hover {
    .TooltipBox {
      display: flex;
    }
  }
`;

const Icon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
`;

const TooltipWrap = styled.div`
  position: absolute;
  left: 20px;
  bottom: 8px;
  display: none;
  justify-content: flex-start;
  align-items: flex-end;
  width: max-content;
`;

const Triangle = styled.div`
  width: 11px;
  height: 11px;
`;

const TooltipBox = styled.p`
  position: relative;
  top: 10px;
  left: -1px;
  padding: 12px;
  max-width: 300px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  background-color: var(--color-DarkGreen);
  opacity: 0.9;
  border-radius: 8px;
`;

function Tooltip({ tooltipTxt, tooltipDP = true }) {
  return (
    <Container display={tooltipDP ? "block" : "none"}>
      <Icon>
        <img src={TooltipIcon} />
      </Icon>
      <TooltipWrap className="TooltipBox">
        <Triangle>
          <img src={TriangleImg} />
        </Triangle>
        <TooltipBox>{tooltipTxt}</TooltipBox>
      </TooltipWrap>
    </Container>
  );
}

export default Tooltip;
