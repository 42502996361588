import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Layout from "../../organisms/ModalLayout";
import SubTitle from "../../atoms/EmphasisTitle";
import SelectWithTitle from "../../molecules/SelectWithTitle";
import Select from "../../atoms/Select";
import Button from "../../atoms/Button";
import CountBox from "../../atoms/CountBox";

import IconSearch from "../../../assets/svg/icon_search.svg";
import {
  useApiKeyDetail,
  useUsageList,
} from "../../../hooks/queries/useApiKeys";
import { useApiKeyUsagePlans } from "../../../hooks/queries/useApiKeyUsagePlans";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const SelectWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 16px 0 0 0;

  & > div {
    margin-right: 12px;
    width: 200px !important;
  }
`;

const CalenderWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 16px;
`;

const StartBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 8px 8px 0;
  padding: 12px;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  background-color: var(--color-GreyGreen);
`;

const TotalBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  width: 200px;
  height: 96px;
  border-radius: 16px;
  background-color: var(--color-BoldGreen);
`;

const TopText = styled.p`
  font-size: 14px;
  font-weidht: 600;
  color: #ffffff;
`;

const BottomText = styled.p`
  font-size: 14px;
  font-weight: 800;
  color: #ffffff;
  text-align: right;
  overflow-wrap: break-word;
`;

function AggregateModal({ modalDP, close, id, projectId }) {
  const nowDate = new Date();
  const [params, setParams] = useState({
    year: nowDate.getFullYear(),
    month: nowDate.getMonth() + 1,
    usage_plan_id: "",
    response_code: "",
    tz: "Asia/Seoul",
    id: id,
    projectId: projectId,
  });
  const [yearList, setYearList] = useState([nowDate.getFullYear()]);
  const [monthList, setMonthList] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);
  const [dayList, setDayList] = useState([]);
  const [usagePlans, setUsagePlans] = useState([]);

  const { data, refetch } = useUsageList({
    ...params,
    date: `${params.year}-${params.month}`,
  });

  const apikey = useApiKeyDetail({ id: id, projectId: projectId });
  const usageplans = useApiKeyUsagePlans({ id: id, projectId: projectId });

  const nowYear = nowDate.getFullYear();

  // 마운트 시점에 parameter setup
  useEffect(() => {
    // 생성된 시점부터 년도 만듬
    if (apikey.data) {
      let createdAt = new Date(apikey.data.data.created_at).getFullYear();
      let createdAtYear = createdAt;
      let years = new Array();
      while (createdAtYear <= nowYear) {
        years.push(createdAtYear);
        createdAtYear = createdAtYear + 1;
      }
      setYearList(years);

      // 올해면 이번달까지만 나오게 하는 로직
      if (params.year === nowYear) {
        let flag = 0;
        let months = [];

        while (flag <= new Date().getMonth()) {
          flag += 1;
          months.push(flag);
        }
        setMonthList(months);
      } else {
        setMonthList([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
      }
      let firstDay = new Date(params.year, params.month - 1, 1).getDate();
      let lastDay = new Date(params.year, params.month, 0).getDate();
      let days = [];
      while (firstDay <= lastDay) {
        days.push(firstDay);
        firstDay += 1;
      }
      setDayList(days);
    }
  }, [apikey.data]);

  const searchHandler = () => {
    let firstDay = new Date(params.year, params.month - 1, 1).getDate();
    let lastDay = new Date(params.year, params.month, 0).getDate();
    let days = [];
    while (firstDay <= lastDay) {
      days.push(firstDay);
      firstDay += 1;
    }
    setDayList(days);
    refetch(params);
  };

  // usageplan 아이디 값 가져오기
  useEffect(() => {
    if (id) {
      if (usageplans.data) {
        let afterUsagePlans = usageplans.data.data;

        if (usagePlans !== afterUsagePlans) {
          setUsagePlans(afterUsagePlans);
        }
      }
    }
  }, [usageplans.data]);

  const selectHandler = (e) => {
    params[e.target.name] = e.target.value;
    setParams({ ...params, [e.target.name]: e.target.value });
  };

  const calendarView = (day) => {
    if (data) {
      let dayMatched = data.data.results.find(
        (row) => Number(Object.keys(row)[0].split("-")[2]) === day
      );
      if (dayMatched) {
        return dayMatched[Object.keys(dayMatched)[0]];
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  return (
    <Layout
      modalDP={modalDP}
      singleButton={false}
      width="1320px"
      modalTitle="API 키 집계"
      defaultButtonName="닫기"
      defaultButtonWidth="200px"
      close={close}
    >
      <Column>
        <SubTitle barTxt="검색조건" />
        <Row>
          <SelectWrap>
            <SelectWithTitle
              selectTitle="기간"
              items={yearList}
              onChange={selectHandler}
              name={"year"}
              value={params.year}
            />
            <Select
              items={monthList}
              onChange={selectHandler}
              name={"month"}
              value={params.month}
            />
            <SelectWithTitle
              selectTitle="사용량계획"
              items={[{ id: "", name: "전체" }].concat(usagePlans)}
              onChange={selectHandler}
              name={"usage_plan_id"}
              value={params.usage_plan_id}
            />
            <SelectWithTitle
              selectTitle="응답코드"
              items={[
                { id: "", name: "전체" },
                { id: "2XX", name: "2XX" },
                {
                  id: "3XX",
                  name: "3XX",
                },
                { id: "4XX", name: "4XX" },
                { id: "5XX", name: "5XX" },
              ]}
              onChange={selectHandler}
              name={"response_code"}
              value={params.response_code}
            />
            <SelectWithTitle
              selectTitle="타임존"
              items={["UTC", "Asia/Seoul"]}
              onChange={selectHandler}
              value={params.tz}
              name={"tz"}
            />
          </SelectWrap>
          <Button
            bgColor="var(--color-NormalGreen)"
            hvColor="var(--color-EmphasisGreen)"
            width="160px"
            imgUrl={IconSearch}
            rightIcon="none"
            txtColor="#ffffff"
            buttonName="검색"
            onClick={searchHandler}
          />
        </Row>
      </Column>
      <Column>
        <SubTitle barTxt="전체 집계량 및 날짜별 집계" />
        <CalenderWrap>
          <StartBox>
            <TopText>날짜</TopText>
            <BottomText>집계랑 &#40;건 &#41;</BottomText>
          </StartBox>
          {dayList.map((row) => (
            <CountBox
              bgColor={
                calendarView(row) ? "var(--color-NormalGreen)" : "#EBF2F1"
              }
              fontColor={calendarView(row) ? "#ffffff" : "#BCD0CD"}
              topTxt={row}
              bottomTxt={calendarView(row)}
              key={row}
            />
          ))}
          <TotalBox>
            <TopText>전체 집계량</TopText>
            <BottomText>{data?.data.count}건</BottomText>
          </TotalBox>
        </CalenderWrap>
      </Column>
    </Layout>
  );
}

export default AggregateModal;
